.product {
  .products {
    padding: 0 0 35px;

    position: relative;

    .splide__arrows {
      top: -65px;
    }
  }

  .splide__slide-link {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.product__details > .product__equipment {
  display: none;
}

.product__gallery {
  .product__equipment {
    display: none;
  }
}

.product__title {
  margin-bottom: 23px;
}

.product__main {
  display: flex;
  margin-bottom: 20px;

  .product__metrics {
    margin: 10px 0 0 0;
    width: 100%;

    .product__metrics-title {
      font-weight: 500;
      color: #232323;
      margin-bottom: 5px;
    }
  }

  .g-tabs-small {
    position: relative;

    .g-tabs-small-labels {
      display: flex;
      justify-content: flex-start;
      font-size: 15px;
      flex-wrap: wrap;

      .g-tab-small-label {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgba(191, 215, 234, 0.3);
        border: none;
        border-bottom: none;
        /*color: #9e9e9e;
				margin: 0 2px 0 0;*/

        &:not(.active):hover {
          /*background-color: #57ace5;*/
          /*border: 2px solid #57ace5;*/
          color: $red;
        }

        &.active {
          /*background-color: #fff;*/
          background-color: #fff;
          border: 1px solid rgba(35, 35, 35, 0.07);
          border-bottom: 3px solid $red;
          cursor: auto;
        }

        [type="radio"] {
          display: none;
        }
      }
    }

    .g-tabs-small-content {
      position: relative;

      .g-tab-small-content {
        display: none;
        border: 1px solid $colorselectorback;

        &.active {
          display: block;
        }

        &.no-border {
          border: none;
        }
      }
    }
  }

  .g-tabs-small.dashed {
    .g-tabs-small-labels {
      /*margin: 0 0 5px 0;*/

      .g-tab-small-label {
        border: 2px dashed #bde0ff;
        border-bottom: 2px dashed #bde0ff;
        background-color: #fff;
        margin: 0 10px 10px 0;

        &.active {
          border: 2px solid #dff0ff;
          border-bottom: 2px solid #dff0ff;
          background-color: #ecf3f9;
        }
      }
    }

    .g-tabs-small-content {
      .g-tab-small-content {
        border: 1px solid #bde0ff;

        &.no-border {
          border: none;
        }
      }
    }
  }

  /*
	.g-tabs-small {
		position: relative;
		display: flex;
		// min-height: 300px;
		// border-radius: 8px 8px 0 0;
		overflow: hidden;

		.g-tab-small {
			flex: 1;

			& label {
				display: block;
				box-sizing: border-box;
				height: 40px;

				padding: 10px;
				text-align: center;
				background: #9768D1;
				cursor: pointer;
				transition: background 0.5s ease;

				&:hover {
					background: #7B52AB;
				}
			}

			[type=radio] {
				display: none;
			}

			[type=radio]:checked~label {
				background: #553285;
				z-index: 2;
			}

			[type=radio]:checked~label~.g-tab-small-content {
				z-index: 1;
				opacity: 1;
			}

			.g-tab-small-content {
				position: absolute;

				left: 0;
				bottom: 0;
				right: 0;
				top: 40px;

				border-radius: 0 0 8px 8px;
				background: #553285;

				transition:
					opacity 0.8s ease,
					transform 0.8s ease;

				opacity: 0;
				transform-origin: top left;

			}

		}
	}
*/
}

.product__gallery {
  flex-basis: 470px;
  max-width: 470px;
  flex-shrink: 0;
  margin-right: 55px;

  .product__image {
    position: relative;
    margin-bottom: 20px;

    a > img {
      max-width: 100%;
    }

    .product__actions-block {
      z-index: 9;
      right: 15px;
      top: 15px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      .product__action-item {
        display: inline-flex;
        position: relative;
        height: 28px;
        font-size: 15px;
        font-weight: 500;
        justify-content: center;
        line-height: 14px;
        padding: 0 10px;
        align-items: center;
        background-color: $red;
        border-radius: 2px;
        color: #fff;

        &.action-skidka {
          background-color: $red;
        }

        &.action-new {
          background-color: $green;
        }

        &.action-hit {
          background-color: $yellow;
        }

        &.more-info {
          svg {
            fill: #ffffff;
            margin-left: 7px;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }

    .product__action {
      z-index: 3;
      height: 28px;
      right: 15px;
      top: 15px;
      position: absolute;
    }

    .product__name {
      z-index: 3;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      position: absolute;
      border-radius: 27px;
      padding: 13px 27px;
      background: rgba(255, 255, 255, 0.6);
      color: $black;

      &:empty {
        display: none;
      }
    }

    .slider-for {
      .slick-next {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowcircleredright.svg);
        top: calc(50% - 20px);
        position: absolute;
        right: 15px;
      }

      .slick-prev {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowcircleredright.svg);
        transform: rotate(180deg);
        top: calc(50% - 20px);
        position: absolute;
        left: 15px;
      }

      .slick-next,
      .slick-prev {
        opacity: 1;
        z-index: 3;
      }

      .slick-next:hover,
      .slick-prev:hover {
        opacity: 1;
      }

      .product__link {
        display: block;

        img {
          display: block;
          width: 470px;
          height: 470px;
        }
      }

      & > a.product__link:not(:first-child) {
        display: none;
        /* по-умолчанию для ботов яндекс и гугла (без JS загрузки страницы) скрыть доп.фото */
      }
    }
  }

  /*Продублировал. Взял из блока ниже*/

  .product__thumbnails {
    .slider-nav {
      padding: 0;
      position: relative;
      margin: 0;
      /*margin: 0 7px;*/
      display: flex;

      .slick-list {
        margin: 0 -7px;
      }

      .slick-track {
        width: auto !important;
        transform: none !important;
      }

      .product__thumbnail {
        position: relative;
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 8px;

        img {
          margin: 7px;
          width: 60px;
          height: 60px;
          object-fit: cover;
        }

        &.slick-current::after {
          content: "";
          left: 0px;
          top: 0px;
          bottom: 0px;
          right: 0px;
          position: absolute;
          border-radius: 3px;
          border: 2px solid $blue;
        }
      }

      .slick-next {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        top: 55px;
        position: absolute;
        right: 0;
      }

      .slick-prev {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        transform: rotate(180deg);
        top: 55px;
        position: absolute;
        left: 0;
      }

      .slick-next,
      .slick-prev {
        opacity: 0.4;
      }

      .slick-next:hover,
      .slick-prev:hover {
        opacity: 1;
      }
    }
  }
}

.product__img {
  margin-bottom: 20px;
  position: relative;

  .splide__arrows {
    display: none;
  }

  .product__action {
    z-index: 3;
    height: 28px;
    right: 15px;
    top: 15px;
  }
}

.product__thumbnails {
  max-width: 390px;
  /*margin: 0 auto;*/

  .splide__arrows--centered-always .splide__arrow--prev {
    left: -50px;
  }

  .splide__arrows--centered-always .splide__arrow--next {
    right: -50px;
  }
}

.product__details {
  flex-grow: 1;

  .product__modifications {
    .slider-nav {
      padding: 0;
      position: relative;

      .slick-list {
        margin: 0 -7px;
      }

      .slick-track {
        width: auto !important;
        transform: none !important;
      }

      .product__thumbnail {
        position: relative;
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 8px;

        img {
          margin: 7px;
          width: 60px;
          height: 60px;
          object-fit: cover;
        }

        &.slick-current::after {
          content: "";
          left: 0px;
          top: 0px;
          bottom: 0px;
          right: 0px;
          position: absolute;
          border-radius: 3px;
          border: 2px solid $blue;
        }
      }

      .slick-next {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        top: 55px;
        position: absolute;
        right: 0;
      }

      .slick-prev {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        transform: rotate(180deg);
        top: 55px;
        position: absolute;
        left: 0;
      }

      .slick-next,
      .slick-prev {
        opacity: 0.4;
      }

      .slick-next:hover,
      .slick-prev:hover {
        opacity: 1;
      }
    }
  }
}

.product__details-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .product-price__current-val {
    font-size: 50px;
    /*line-height: 1.45;*/
    line-height: 1;
  }
}

.product-price__note {
  font-size: 13px;
  line-height: 1.35;
  color: $black;
  opacity: 0.3;
  margin-top: 30px;
  max-width: 214px;

  &.bright {
    opacity: 0.4;
    margin-top: 0;
  }

  &.warning {
    opacity: 1;
    margin-top: 0;
    background-color: #ff1717;
    color: white;
    padding: 8px;
  }
}

.product-price__addition {
  flex-basis: 100%;
  font-size: 15px;
  width: 250px;

  label.input_field {
    position: relative;
    color: $black;
    font-size: 15px;
    text-align: right;
    margin: 0 5px;
    opacity: 1;

    input {
      height: auto;
      width: 30px;
      border: none;

      border-bottom: 1px solid rgba(35, 35, 35, 0.3);
      /*
			background-color: rgba(191, 215, 234, 0.3);
			border-bottom: none;
			padding: 3px;
			*/

      margin-top: 0;
      text-align: right;
    }

    span {
      // position: absolute;
      // top: 3px;
      // right: 20px;
    }
  }

  .product-price__addition-qty {
    .product-price__addition-amount {
      margin-top: 3px;
    }
  }

  .product-price__addition-delivery {
    .price-delivery-amount {
      line-height: 1.5;

      .delivery_address_choose {
        cursor: pointer;

        span {
          padding: 5px;
          text-decoration: underline;
        }
      }
    }
  }

  .product-price__thickness-choose {
    .product-price__thickness-select {
      border: 1px solid #23232323;
    }
  }

  .price__addition-block {
    margin-top: 12px;
  }

  .button-number-modifier {
    color: $red;
    font-weight: bold;
    padding: 0 7px;
    border-radius: 50%;
    transition: background 0.5s ease;

    &.modifier-decrease {
      margin-left: -7px;
    }

    &:hover {
      background: #fff1ef;
    }
  }

  .popup__form-textarea {
    height: 40px;
    margin: 0 0 10px 0;
  }

  .back-gray {
    background-color: #23232323;

    span {
      opacity: 1;
    }
  }

  .flex-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .flex-fullwidth {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .flex_align-rigth {
    flex-basis: auto !important;
    margin-left: auto;
  }

  .sub-info {
    //flex-basis: 50%;
    font-size: 12px;
    margin-top: 3px;
    opacity: 0.6;
  }

  a,
  a:visited {
    color: $black;
    text-decoration: underline;
  }
}

.product__equipment {
  flex-basis: 335px;
  max-width: 335px;
  background-color: rgba($spindle, 0.2);
  border-radius: 3px;
  padding: 20px 35px 19px 32px;
  font-size: 16px;
  line-height: 1.45;
  color: rgba(35, 35, 35, 0.4);

  strong {
    font-weight: 500;
    color: $black;
  }

  hr {
    display: block;
    margin: 12px 0;
    border: 0;
    height: 1px;
    background-color: #232323;
    opacity: 0.07;
  }

  @media (max-width: 1199px) {
    flex-basis: 270px;
    max-width: 270px;
    padding: 20px 25px 19px 22px;
  }
}

.product__equipment-title {
  line-height: 1.4;
  color: $red;
  margin-bottom: 18px;
  font-weight: 500;
}

.product__subtitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: $black;
  margin-bottom: 25px;
}

.product__thickness {
  margin-bottom: 45px;
}

.product__thickness-list {
  max-width: 612px;

  li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba($black, 0.15);
  }
}

.product__thickness-type {
  color: rgba($black, 0.4);
}

.product__thickness-price {
  flex-basis: 300px;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
}

.product__thickness-price-old {
  color: rgba($black, 0.6);
  /*padding-right: 10px;
	text-decoration: line-through;*/
  margin-right: 10px;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    background-color: $red;
    display: inline-block;
    position: absolute;
    height: 1.4px;
    left: 0;
    top: 45%;
    transform: rotate(-9deg);
    width: 100%;
  }
}

.product__btns {
  display: flex;
  /*margin-left: 525px;*/
  justify-content: flex-end;
  margin-bottom: 80px;

  .g-btn {
    margin-right: 20px;
  }

  .g-btn {
    height: 50px;
  }

  .g-btn__icon {
    width: 17px;
    height: 17px;
    margin-right: 9px;
  }
}

.product__section {
  margin-bottom: 55px;

  .g-tabs-nav {
    width: 100%;
    flex-wrap: wrap;

    &.g-tabs-short {
      width: auto;
    }
  }

  .g-tabs-nav__btn {
    padding: 0 47px;
  }

  .examples-work-group {
    position: relative;

    .splide__arrows {
      top: -65px;
    }

    .slick-slide {
      margin: 0 15px;
    }

    .examples-work {
      display: flex;
      overflow: auto hidden;
    }
  }

  & > .product__tab-trg {
    border-top: 0;
  }

  & > .product__tab-trg ~ .product__tab-trg {
    border-top: 1px solid rgba($black, 0.15);
  }
}

.product__section-title {
  margin-bottom: 20px;
}

.product__photo-carousel {
  .splide__arrows--centered .splide__arrow--prev {
    left: -74px;
  }

  .splide__arrows--centered .splide__arrow--next {
    right: -74px;
  }
}

.product__tab {
  height: 0;
  overflow: hidden;

  &.is-active {
    padding-top: 40px;
    height: auto;
    overflow: visible;
  }
}

.surface__tab {
  height: 0;
  overflow: hidden;

  &.is-active {
    padding-top: 40px;
    height: auto;
    overflow: visible;
  }
}

.product__descr {
  margin-bottom: 50px;

  h4 {
    margin-bottom: 20px;
  }

  p {
    margin-top: 0;
    line-height: 1.75;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  ul {
    padding-left: 22px;
    margin-bottom: 10px;

    li {
      padding-left: 21px;
      position: relative;
      line-height: 1.5;
      margin-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #d0271a;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
  }
}

.product__descr-title,
.product__descr-text {
  margin-bottom: 25px;
}

.product__chars {
  margin-bottom: 55px;

  .product__tables {
    margin-bottom: 20px;
  }
}

.product__chars-title {
  margin-bottom: 40px;
}

.product__tables {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product__table {
  flex-basis: 532px;
  max-width: 532px;
}

.product__tables-column {
  flex-basis: 532px;
  max-width: 532px;
}

.product__table-title {
  margin-bottom: 30px;
}

.g-table {
  width: 100%;
  margin-bottom: 40px;
}

.g-table__row {
  display: flex;
  justify-content: space-between;

  padding-bottom: 17px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($black, 0.15);
    margin-bottom: 17px;
  }

  //padding-bottom: 40px;
  width: 100%;
}

.g-table__row-name {
  opacity: 0.5;
}

.g-table__row-value {
  /*	flex-basis: 30%;
	max-width: 30%;*/
  flex-basis: 55%;
  max-width: 55%;
}

.prop-cards {
  display: flex;
  margin: 0 -15px;
}

.prop-card {
  flex-basis: 25%;
  max-width: 25%;
  padding: 0 15px;
}

.prop-card__inner {
  height: 100%;
  padding: 35px 25px;
  border: 1px solid rgba($blue, 0.4);
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: right 25px bottom 25px;
}

.prop-card__title {
  font-weight: 500;
  margin-bottom: 15px;
}

.prop-card__descr {
  line-height: 1.7;
}

.product__preview-list {
  display: flex;
  margin: 0 -15px;
}

.product__preview {
  flex-basis: 25%;
  max-width: 25%;
  padding: 0 15px;
  margin-bottom: 15px;
}

.product-cover__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px 35px;
}

.product-cover__item {
  flex-basis: 100px;
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 20px;

  &.is-active {
    .product-cover__item-img::before {
      position: absolute;
      content: "";
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -7px;
      left: -6px;
      background-color: transparent;
      border: 2px solid #57ace5;
      border-radius: 3px;
    }

    .product-cover__item-name {
      color: $blue;
    }
  }
}

.product-cover__item-img {
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.product-cover__item-name {
  font-weight: 500;
}

.product-cover__descr {
  margin-bottom: 50px;

  img {
    width: 421px;
    height: auto;
    float: left;
    margin-right: 50px;
    margin-bottom: 37px;
  }

  h3 {
    margin-bottom: 25px;
  }

  p {
    line-height: 1.75;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.product-cover-colors__list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 25px;
  margin: 0 -6.5px;
  max-height: 588px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #dedede;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $red;
  }
}

.product-cover-colors__item {
  flex-shrink: 0;
  flex-basis: 16.6%;
  max-width: 16.6%;
  padding: 0 6.5px;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 35px;
}

.product-cover-colors__title {
  margin-bottom: 25px;
}

.product-cover-colors__item-color {
  width: 100%;
  height: 100px;
  border-radius: 2px;
  margin-bottom: 12px;
}

.product-cover-colors__item-code {
  margin-bottom: 8px;
}

.product-cover-colors__item-name {
  color: rgba($black, 0.4);
}

.product-docs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.product-doc {
  flex-basis: calc(100% / 3);
  max-width: calc(100% / 3);
  padding: 0 15px;
  margin-bottom: 25px;
}

.product-doc__inner {
  border: 1px solid rgba($black, 0.15);
  border-radius: 3px;
  height: 208px;
  padding: 20px 15px;
}

.product-doc__inner {
  border: 1px solid rgba($black, 0.15);
  border-radius: 3px;
  height: 208px;
  padding: 20px 15px;
}

.product-doc__top {
  display: flex;
  /*align-items: center;*/
  margin-bottom: 15px;
}

.product-doc__icon {
  flex-shrink: 0;
  flex-basis: 55px;
  max-width: 55px;
  margin-right: 15px;
}

.product-doc__type {
  font-size: 15px;
  color: rgba($black, 0.4);
  margin-bottom: 12px;
}

.product-doc__name {
  font-weight: 500;
  line-height: 1.35;
}

.product-doc__fileinfo {
  color: rgba($black, 0.4);
  line-height: 1;
  font-size: 15px;
  margin-top: 5px;
}

.product-doc__bottom {
  padding-left: 70px;
}

.product-doc__open {
  margin-right: 27px;
}

.product__section {
  .tone_section {
    display: flex;

    .tone_color {
      display: flex;
      margin: 0 10px 0 0;
      text-align: center;
      align-items: center;
      padding: 0 5px;

      span {
        filter: invert(1);
        font-weight: 500;
      }
    }
  }
}

.product__metrics {
  .product__metrics-chart {
    display: grid;
    grid-auto-flow: column;
    gap: 1px;
    background-color: #fff;
    /*border: 1px solid black;*/
    /*padding: 10px;*/
    box-sizing: border-box;
    align-items: flex-end;
    /*width: 300px;*/
    height: 200px;
  }

  .product__metrics-bar {
    position: relative;
    bottom: 0;
    transition: all 0.3s ease;
    transform-origin: bottom;
    cursor: pointer;
    border: 1px solid #eaeaea;

    &.product__metrics-bar-current {
      border: 2px solid #57ace5;
    }

    &:hover {
      /*transform: scale(1.8, 1.2);*/
      transform: scale(1.5);
      z-index: 1;
      /*border: 1px solid #cdcdcd;*/

      .product__metrics-bar-caption {
        display: block;
        /*transform: scale(0.55, 0.83);*/
      }
    }

    .product__metrics-bar-caption {
      display: none;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 12px;
      border-radius: 3px;
    }
  }
}

@media (max-width: 1199px) {
  .product__btns {
    margin-left: 450px;
  }
}

@media (max-width: 1023px) {
  .product {
    .product__gallery .product__image .slider-for .product__link img {
      display: block;
      width: 280px;
      height: 280px;
    }

    .product__details-top > .product__equipment {
      display: none;
    }

    .product-thumbs {
      margin: -21px 0px 0 -21px;
    }
  }

  .product__gallery {
    flex-basis: 280px;
    max-width: 280px;
    margin-right: 35px;

    .product__equipment {
      display: block;
      /*margin-top: 25px;*/
      flex-basis: auto;
      max-width: 100%;
    }
  }

  .product__img .splide__arrows {
    display: flex;
    padding: 0 15px;
  }

  .product__thumbnails {
    .splide__arrows {
      display: none;
    }
  }

  .product__btns {
    margin-left: 0;
    justify-content: flex-end;
  }

  .product__preview {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }

  .product__tables-column {
    flex-basis: 100%;
    max-width: 100%;
  }

  .g-table__row-value {
    flex-basis: 30%;
    max-width: 30%;
  }

  .prop-cards {
    flex-wrap: wrap;
  }

  .prop-card {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }

  .product__section .g-tabs-nav__btn {
    padding: 0 37px;

    &:last-child {
      padding: 0 30px;
    }
  }

  .product-cover__items {
    margin: 0 -4px 25px;
  }

  .product-cover__item {
    padding: 0 10px;
  }

  .product-cover__descr {
    margin-bottom: 40px;

    h3 {
      margin-bottom: 15px;
    }

    img {
      width: 280px;
      margin-right: 30px;
      margin-bottom: 20px;
    }

    p:not(:last-child) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .product-cover-colors {
    max-width: 623px;
  }

  .product-cover-colors__list {
    margin: 0 -12px;
    max-height: 518px;
  }

  .product-cover-colors__item {
    flex-basis: 33%;
    max-width: 33%;
    padding: 0 12px;
    margin-bottom: 20px;
  }

  .product-doc {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }

  .product-doc__inner {
    height: 202px;
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .product__main {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;

    .product__gallery .product__image .slider-for .product__link img {
      display: block;
      width: 470px;
      height: 470px;
      /*width: 70%;
			height: 70%;*/
      object-fit: cover;
      object-position: center center;
    }

    .product__gallery .product__equipment {
      display: none;
    }

    .product__details {
      display: flex;
      flex-wrap: wrap;

      .product__equipment {
        display: block;
      }

      .product__details-top {
        .product__equipment {
          display: none;
        }
      }

      .product__modifiers {
        /*order: -1;*/
      }

      .product__details-top {
        width: 100%;
        /*margin-bottom: 0;*/
        margin-bottom: 5px;
        margin-top: 0;
      }

      .product__thickness {
        margin-bottom: 0px;
        width: 100%;
      }

      .product__thickness-list {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .product__gallery {
    flex-basis: 290px;
    /*max-width: 100%;*/
    max-width: 470px;
    margin-right: 0;

    .splide__arrow svg circle {
      fill: #fff;
    }

    .product__image {
      margin-bottom: 10px;

      a > img {
        /*max-width: initial;*/
      }
    }
  }

  .product__thumbnails {
    display: none;
  }

  .product__details {
    flex-basis: 100%;
  }

  .product__equipment {
    margin-left: 0;
    padding: 15px 31px 15px 20px;
    font-size: 13px;
    /*margin-bottom: 20px;*/
    margin-top: 10px;

    hr {
      margin: 10px 0;
    }
  }

  .product__equipment-title {
    font-size: 13px;
    margin-bottom: 13px;
  }

  .product__details-top {
    .product-price {
      /* align-items: center; */
      display: flex;
      flex-wrap: wrap;
      flex-flow: column;
      width: 100%;
    }

    .product-price__current-val {
      font-size: 38px;
      /*line-height: 1.45;*/
    }

    .product-price__note {
      //   display: none;
    }

    .product-price__old {
      margin-left: 15px;
    }
  }

  .product__subtitle {
    font-size: 14px;
    margin-bottom: 15px;
    /*margin-top: 20px;*/
  }

  .product__thickness-list {
    font-size: 13px;

    li {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  .product__thickness-price {
    flex-basis: 173px;
  }

  .product .product-thumbs {
    margin: 0;
  }

  .product__btns {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .g-btn {
      margin-right: 0;
      margin-bottom: 5px;
    }

    .g-btn-transp,
    .g-btn {
      width: 100%;
      height: 43px;
    }

    .g-btn-compare {
      order: 3;
      margin-top: 5px;
      margin-right: 0;
    }
  }

  .product__photo-carousel {
    .splide__arrows {
      width: unset;
      top: -55px;
    }
  }

  .product__tab-trg {
    position: relative;
    height: 53px;
    background-color: rgba($spindle, 0.3);
    line-height: 53px;
    padding: 0 15px;
    font-weight: 500;
    border-top: 1px solid rgba($black, 0.15);
    margin: 0 -15px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      right: 20px;
      top: 18px;
      width: 16px;
      height: 16px;
      background-image: url(../img/icons/transp-arrow.svg);
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.3s;
    }

    &.is-active::after {
      transform: scale(1, -1);
    }

    &.is-active + .product__tab {
      height: auto;
      overflow: visible;
      padding-top: 20px;
    }
  }

  .product__tab.is-active {
    padding-top: 0;
    height: 0;
    overflow: hidden;
  }

  .product__descr-title,
  .product__descr-text {
    margin-bottom: 10px;
  }

  .product__descr {
    margin-bottom: 25px;

    ul {
      padding-left: 0;
    }
  }

  .g-table__row-value {
    flex-basis: unset;
    max-width: unset;
  }

  .g-table {
    margin-bottom: 13px;
  }

  .product__chars {
    margin-bottom: 25px;
  }

  .prop-card {
    flex-basis: 100%;
    max-width: 100%;
  }

  .product__preview-list {
    margin: 0 -12px;
  }

  .product__preview {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 12px;
  }

  .product__section-title {
    margin-bottom: 10px;
  }

  .product .products .splide__arrows {
    top: unset;
    bottom: -10px;
  }

  .product-cover__items {
    margin: 0 -9px 25px;
  }

  .product-cover__item {
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 9px;
    margin-bottom: 13px;
  }

  .product-cover__item-img {
    margin-bottom: 13px;
  }

  .product-cover__descr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    img {
      order: 2;
      max-width: 100%;
      height: auto;
      width: unset;
      float: unset;
      margin-bottom: 15px;
      margin-right: 0;
    }

    h3 {
      order: 1;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
      line-height: 1.5;
      order: 3;
    }
  }

  .product-cover-colors__title {
    margin-bottom: 12px;
  }

  .product-cover-colors__list {
    max-height: unset;
    padding-right: 0;
    margin: 0 -10px 20px;
  }

  .product-cover-colors__item {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  .product-doc {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .product-doc__inner {
    height: 153px;
    padding: 12px;
  }

  .product-doc__top {
    margin-bottom: 10px;
  }

  .product-doc__type {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 6px;
  }

  .product-doc__name {
    font-size: 14px;
    line-height: 1.25;
  }

  .product-doc__fileinfo {
    font-size: 13px;
  }

  .product-doc__icon {
    flex-basis: 50px;
    max-width: 50px;
    height: 50px;

    img {
      max-width: 100%;
    }
  }

  .product-doc__bottom {
    padding-left: 76px;
  }

  .prop-cards {
    margin-top: 20px;
  }
}

@media (max-width: 515px) {
  .product__title {
    margin-bottom: 5px;
  }

  .product__main {
    .product__gallery {
      flex-basis: 470px;
      max-width: 100%;

      .product__image {
        margin-bottom: 5px;

        .slider-for .product__link img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .product__subtitle {
      margin-bottom: 7px;
    }

    .product__details-top {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}
