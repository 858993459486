.action-preview {

}

.action-preview__img {
	position: relative;

	img, picture, source {
		width: 100%;
	}
	
	.action-preview__deadline {
		height: 32px;
		position: absolute;
		bottom: 0;
		padding: 0 12px;
		z-index: 3;

		align-items: center;
		justify-content: center;
		background-color: #dd2d1f;
		color: #fff;
		border-radius: 2px;
		display: inline-flex;
		font-size: 15px;
		font-weight: 500;
		height: 27px;
		line-height: 14px;
		
		&.action-red {
			background-color: $red;
			color: #fff;
		}
		&.action-black {
			background-color: $black;
			color: #fff;
		}
		&.action-white {
			background-color: #fff;
			color: $black;
		}
		&.action-blue {
			background-color: $blue;
			color: #fff;
		}
		
		
	}
	
}

.action-preview__date
{
	color: rgba($black, .35);
	font-size: 15px;
	line-height: 1.75;
	margin-bottom: 8px;
}

.action-preview__title, .action-preview__descr
{
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}

.action-preview__title
{
	@include roboto(500);
	font-size: 16px;
	line-height: 1.65;
	color: $black;
	margin-bottom: 5px;
	transition: .135s;

	&:hover
	{
		color: $red;
	}

	&:focus, &:active
	{
		color: $red-click;
	}
}

.action-preview__descr
{
	font-size: 16px;
	line-height: 1.75;
	color: rgba($black, .4);
	margin-bottom: 10px;
	margin-top: auto;
}

@media (max-width: 1023px)
{
	.action-preview
	{
		/*flex: 0 0 calc(100% / 3);
		max-width: calc(100% / 3);*/
	}

	.action-preview__img
	{
		/*height: 218px;*/
		margin-bottom: 13px;
	}

	.action-preview__title, .action-preview__descr
	{
		-webkit-line-clamp: 4;
	}

}

@media (max-width: 767px)
{
	.action-preview
	{
		/*flex: 0 0 50%;
		max-width: 50%;
		padding: 0 10px;
		margin-bottom: 23px;*/
	}
	
	.action-preview__img
	{
		margin-bottom: 7px;
	}

	.action-preview__title, .action-preview__descr
	{
		-webkit-line-clamp: 5;
		font-size: 13px;
		line-height: 1.45;
	}

}

@media (max-width: 479px)
{
	.action-preview__img
	{
		/*height: 135px;*/
	}
}