.filters {
	border: 1px solid rgba($black, .15);
	border-radius: 3px;

	&:not(:last-child) {
		margin-bottom: 15px;
	}
}

.filters__list {
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: hidden;
	display: flex;
    flex-direction: column;

	/*	max-height: 400px;
	overflow-x: hidden;
    overflow-y: auto;*/
	&::-webkit-scrollbar {
		//width: 6px;
	}

	&.show-all-items {
		max-height: 140px;
	}

}


.filters__item {
	&:not(:first-child) {
		.filters__item-head {
			border-top: 1px solid rgba($black, .15);
		}
	}
}

.filters__item-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 31px 12px 23px;
	cursor: pointer;

	&.is-active {
		border-bottom: 1px solid rgba($black, .15);

		&+.filters__item-content {
			display: block;
		}

		.filters__item-icon {
			transform: scale(1, -1);
		}
	}
}

.filters__item-name {
	font-size: 16px;
	line-height: 1.45;
	font-weight: 500;

	&--red {
		color: $red;
	}
}

.filters__item-icon {
	font-size: 0;
	transition: .3s;
}

.filters__item-content {
	display: none;
	padding: 13px 20px 10px 23px;

	&--btns {
		border-top: 1px solid rgba($black, .15);
		display: block;

		button {
			width: 100%;
		}

		.g-btn {
			margin-bottom: 8px;
		}
	}
}

.filters__list-item {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	/*cursor: pointer;*/

	&>label {
		display: flex;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	&:hover {
		.filters__list-item-name {
			color: $black;
		}

		.g-checkbox__custom {
			opacity: 1;
		}
	}
	
	&.checked {
		order: -1;
	}
}

.filters__list-item-name {
	color: rgba($black, .5);
	line-height: 1.25;
	transition: .135s;
	margin-right: 30px;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 15px;
}

.filters__list-item-quantity {
	align-self: flex-start;
	min-width: 40px;
	text-align: center;
	padding: 3px 8px 2px 7px;
	font-size: 14px;
	line-height: 1.25;
	background-color: rgba($spindle, .3);
	color: rgba($black, .55);
	margin-left: auto;
	flex-shrink: 0;
}

.filters__list-item-color,
.filters__list-item-tone {
	flex-shrink: 0;
	border-radius: 2px;
	width: 17px;
	height: 17px;
	margin-right: 8px;
}

@media (max-width: 1099px) {
	.filters {
		display: none;
		margin-bottom: 10px;

		&.is-active {
			display: block;
		}
	}
}