iframe[src*="youtube"] {
	max-width: 100%;
	max-height: 100%;
}

.youtube-video
{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	font-size: 0;
	border-radius: 3px;
	overflow: hidden;

	&>img, iframe
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

.youtube-video__play
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba($red, 0);
	cursor: pointer;
	transition: 0.5s;
	img {
		transition: 0.5s;
		opacity: 0.5;
	}
}
.youtube-video__play:hover {
	background-color: rgba($red, .75);
	img {
		opacity: 1;
	}
}