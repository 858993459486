.breadcrumbs
{
	margin-bottom: 10px;

	.container
	{
		display: flex;
		flex-wrap: wrap;
	}
}

.breadcrumbs__item
{
	color: $blue;
	font-size: 14px;
	line-height: 1.45;

	&:not(:last-child)
	{
		margin-right: 8px;

		&::after
		{
			content: '>';
			margin-left: 6px;
		}
	}

	&:last-child
	{
		color: $black;
		cursor: text;
		pointer-events: none;
	}
}


@media (max-width: 767px)
{
	.breadcrumbs
	{
		margin-bottom: 10px;
		margin-top: -20px;
		padding-bottom: 9px;
		padding-top: 9px;
		border-top: 1px solid #d6effb;
		background-color: rgba(191, 215, 234, 0.3);
	}


	.breadcrumbs__item
	{
		&:not(:nth-last-child(2))
		{
			display: none;
		}
		&:not(:last-child)::after
		{
			content: none;
		}


		&:nth-last-child(2)::before
		{
			content: '';
			display: inline-block;
			height: 12px;
			width: 12px;
			margin-right: 4px;
			position: relative;
			top: 1px;	
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3E%3Cpath fill='none' stroke='%2357ACE5' stroke-linecap='square' stroke-miterlimit='10' stroke-width='1.40628' d='M10.254 13.008L4.746 7.5m0 0l5.508-5.508'/%3E%3C/svg%3E");		
		}		
		
	}

}