$blue: #57ace5;
$red: #dd2d1f;
$black: #232323;
$lightblue: #bfd7ea;
$white: #fff;
$carmine-pink: #ee4c34;
$gray: rgba($black, 0.4);

$text-color: $black;
$body-bg: $white;
$red-rgba: rgba($red, 1);
$blue-rgba: rgba($blue, 1);

/* Наши SCSS-переменные */
$theme-colors: (
  body: $black,
  primary: $red,
  secondary: $blue,
  success: $red,
  info: $blue,
  warning: $red,
  danger: $red,
);

/* Глобальные переменные Bootstrap */
$body-bg: #fff;
$body-color: $text-color;
$muted-color: rgba($body-color, 0.4);
$text-muted: $muted-color;

/* Цвета для компонентов */
$component-active-color: #fff;
$component-active-bg: map-get($theme-colors, "primary");

/* Кнопки */
$btn-primary-color: #fff;
$btn-primary-bg: map-get($theme-colors, "primary");
$btn-primary-border-color: map-get($theme-colors, "primary");

$btn-secondary-color: #fff;
$btn-secondary-bg: map-get($theme-colors, "secondary");
$btn-secondary-border-color: map-get($theme-colors, "secondary");

$border-primary: $red;

/* Экспортируем SCSS-переменные как CSS-переменные */
:root {
  --bs-primary: #{$red};
  --bs-primary-rgb: #{red($red)}, #{green($red)}, #{blue($red)};
  --bs-secondary: #{$blue};
  --bs-secondary-rgb: #{red($blue)}, #{green($blue)}, #{blue($blue)};
  --bs-body-bg: #{$body-bg};
  --bs-body-color: #{$body-color};
  --bs-muted: #{$muted-color};
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;

  /* Цвета текста */
  --bs-primary-text: #{$body-color};
  --bs-secondary-text: #{$blue};
  --bs-secondary-color: #{$gray};
  --bs-secondary-color-rgb: #{red($gray)}, #{green($gray)}, #{blue($gray)};

  /* Цвета фона */
  --bs-primary-bg: #{$red-rgba};
  --bs-secondary-bg: #{$blue};

  /* Дополнительные цвета */
  --bs-border-color: #{$muted-color};
  --bs-border-primary: #{$red};
  --bs-button-transform-color: #{$carmine-pink};

  /* Дополнительные пользовательские переменные */
  --primary-color: #{$red};
  --secondary-color: #{$blue};
  --success-color: #{$red};
  --info-color: #{$blue};
  --warning-color: #{$red};
  --danger-color: #{$red};
}

/* End Bootstrap variables declarations */

.cover {
  object-fit: cover !important;
}

.contain {
  object-fit: contain !important;
}

.main-section {
  padding: 100px 0;
}

.houses-construction {
  img,
  svg {
    vertical-align: middle;
  }
  a {
    color: var(--headings-color);
    text-decoration: none;
    outline: none;
    transition: all ease 0.4s;
  }

  section {
    padding: 60px 0;
    position: relative;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-weight: 600;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }

  .card-title {
    font-size: 20px;
  }

  .section-title {
    margin-bottom: 60px;
    color: var(--headings-color);
    font-family: var(--title-font-family);
    h2 {
      font-size: 30px;
      margin-bottom: 0 !important;
    }
    p {
      margin: 0 0 15px 0;
    }
  }
}

.hero-section {
  background-image: url(../img/construct/hero-banner.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  align-items: center;
  display: flex;
  height: 660px;
}

.border-primary {
  border-color: var(--primary-color) !important;
  border-radius: 12px;
}

.accordion-style1 {
  position: relative;
  margin-bottom: 30px;
}
.accordion-style1 .accordion-item {
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 4px 30px;
  transition: all 0.5s ease-out;
}
.accordion-style1 .accordion-item.is-active {
  -webkit-box-shadow: 0 10px 40px rgb(24 26 32 / 5%);
  -o-box-shadow: 0 10px 40px rgb(24 26 32 / 5%);
  box-shadow: 0 10px 40px rgb(24 26 32 / 5%);
}
.accordion-style1 .accordion-item.is-active .accordion-button::before {
  transform: rotateZ(180);
}
.accordion-style1 .accordion-header {
  border: none;
}
.accordion-style1 .accordion-button {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
}
.accordion-style1 .accordion-button::before {
  color: #041e42;
  background-image: url(../img/icons/angle-down.svg);
  background-size: contain;
  content: "";
  width: 14px;
  height: 14px;
  left: 0;
  position: absolute;
}
.accordion-style1 .accordion-button::after {
  display: none;
}
.accordion-style1 .accordion-body {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding: 10px 0 15px;
  max-width: 410px;
}
.accordion-style1.faq-page .accordion-body {
  max-width: initial;
}
.accordion-style1.style2 .accordion-item {
  border: none;
  padding: 0;
}
.accordion-style1.style2 .accordion-item.is-active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.accordion-style1.style2 .accordion-header {
  border: none;
  border-bottom: 1px solid #ddd;
}
.accordion-style1.style2 .accordion-body {
  max-width: initial;
  padding: 0;
}

.form-site label::after {
  content: "";
  margin-left: 5px;
  font-weight: bold;
  color: red;
}

.form-site input[required] + label::after {
  content: "*";
}

.project-item {
  margin-bottom: 30px;
  position: relative;

  .list-thumb {
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    position: relative;
  }

  .list-content {
    position: relative;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 12px;
    bottom: 10px;
    left: 10px;
    right: 10px;

    &.compact-view {
      right: 0;
      bottom: 0;
      left: 0;

      padding: 10px 20px;

      border: 1px solid #0000001f;
      border-radius: 0 0 12px 12px;
    }
  }

  .list-price {
    top: 21px;

    padding: 0;

    font-size: 20px;

    color: var(--headings-color);
    font-family: var(--title-font-family);
    font-weight: 600;
    right: 20px;
    position: absolute;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .list-title {
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 10px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list-title a {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .list-title a:hover {
    color: var(--headings-color);
  }
  .list-text {
    color: #717171;
    margin-bottom: 0;
  }

  img {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  &:hover {
    .list-price {
      color: var(--primary-color);
      background-color: initial;
    }
    .list-tag,
    .list-tag2 {
      opacity: 0%;
      visibility: hidden;
      -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
      transform: translateY(50px);
    }

    .list-meta {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    .list-thumb img {
      -webkit-transform: scale(1.1) rotate(-1deg);
      -ms-transform: scale(1.1) rotate(-1deg);
      transform: scale(1.1) rotate(-1deg);
    }

    a {
      color: initial;
    }
  }

  @media (max-width: 991.98px) {
    .list-price {
      display: inline-block;
      left: 0;
      position: relative;
      top: 0;
    }
  }

  @media (max-width: 360px) {
    .list-content {
      border: 1px solid #ddd;
      left: 0;
      position: relative;
      right: 0;
    }
  }
}

@media (width <= 991.98px) {
  .project-item .list-price {
    top: 0;
  }
}

.houses-project-in-slider {
  .project-item {
    img {
    }
  }
}

.list-one {
  .list-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      color: #fff;
      fill: var(--primary-color);
      transition: all 0.4s ease;
    }
  }

  &:hover .list-icon svg {
    color: var(--primary-color);
    fill: #fff;
  }
}

.mirror-image-button {
  position: absolute;
  z-index: 3;
  right: 20px;
  bottom: 20px;

  display: block;

  padding: 7px 20px;

  font-family: var(--title-font-family);
  font-weight: 600;
  color: var(--headings-color);

  background-color: #fff;
  border-radius: 6px;
}

.big-image-container {
  position: relative;
}

.custom-prev,
.custom-next {
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);

  opacity: 0%;
  background-color: #9696966e;

  transition: opacity 0.3s ease;
}

.custom-prev {
  left: 10px;

  &:hover {
    background-color: #969696;
  }
}

.custom-next {
  right: 10px;

  &:hover {
    background-color: #969696;
  }
}

.big-image-container:hover .custom-prev,
.big-image-container:hover .custom-next {
  opacity: 100%;
}

/* Equipment Card */

.equipments-container {
  padding: 0;

  text-align: center;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
}

.house-project-price-side {
  @media (min-width: 1024px) {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.equipments-header {
  display: flex;
  align-items: end;
  justify-content: space-between;

  margin: 0;
  padding: 20px 30px;

  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  color: var(--primary-color);

  .equipments-header-title {
    font-size: 20px;
    font-weight: normal;
    color: var(--bs-body-color);
  }
}

.equipments-list {
  margin: 0;
  padding: 20px 30px;
  list-style: none;

  .equipments-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;

    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .equipment-title {
    flex: 1;
    text-align: left;
  }

  .equipment-price {
    flex: 1;
    font-weight: bold;
    text-align: right;
  }
}

.background-number {
  pointer-events: none;

  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 15rem;
  font-weight: bold;
  color: rgb(0 123 255 / 8%);
  text-align: center;
}

.why-chose-list .list-one .list-icon.list-icon-for-svg {
  line-height: 62px;
}

.houses-construction-reviews {
  .testimonial-style1 {
    background-color: #fff;
    border-radius: 12px;
    -o-box-shadow: 0 1px 4px rgba(24, 26, 32, 0.07);
    box-shadow: 0 1px 4px rgba(24, 26, 32, 0.07);
    margin-bottom: 15px;
    padding: 30px 10px 30px 30px;

    .title {
      font-size: 16px;
      color: var(--headings-color);
      font-family: var(--title-font-family);
      font-weight: 600;
      margin-bottom: 25px;
    }

    .testimonial-content {
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .icon {
      position: absolute;
      right: 30px;
      top: 15px;
      width: 36px;
      transform: rotateZ(180deg);
    }
  }
}

.houses-construction-payment-stages {
  .icon-wrapper {
    img {
      width: 40px;
    }
  }
}

.houses-construction-payment-methods {
  .icon-wrapper {
    img {
      width: 40px;
    }
  }
}

.houses-construction-why-we {
  .cta-banner3::before {
    background-image: url(../img/construct/cta-side-bg-tfkk.jpg);
    background-size: cover;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 42%;
    transform: scaleX(-1);
    background-position-x: 80%;
    @media (max-width: 767.98px) {
      display: none;
    }
  }

  .list-one {
    &:hover .list-icon {
      background-color: rgba(235, 103, 83, 0.6);
      color: #fff;
    }

    .list-icon {
      background-color: rgba(235, 103, 83, 0.07);
      border-radius: 50%;
      color: var(--primary-color);
      display: block;
      font-size: 30px;
      height: 70px;
      line-height: 80px;
      text-align: center;
      width: 70px;
      transition: all 0.4s ease;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.houses-construction-faq {
  .accordion-item {
    .accordion__item-head {
      padding: 0;
      border: none;
    }
    .accordion__item-content {
      padding: 0;
    }
  }
}

.formCallback {
  label::after {
    content: "";
    margin-left: 5px;
    font-weight: bold;
    color: red;
  }

  input[required] + label::after {
    content: "*";
  }
}

.house-project-gallery {
  height: auto;

  @media (min-width: 1024px) {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .house-project-image {
    position: relative;
    margin-bottom: 20px;

    a > img {
      max-width: 100%;
    }
  }

  .slider-house-projects-for {
    .slick-next {
      display: block;
      width: 40px;
      height: 40px;
      font-size: 0;
      color: transparent;
      background: url(../img/arrowcircleredright.svg);
      top: calc(50% - 20px);
      position: absolute;
      right: 15px;
    }

    .slick-prev {
      display: block;
      width: 40px;
      height: 40px;
      font-size: 0;
      color: transparent;
      background: url(../img/arrowcircleredright.svg);
      transform: rotate(180deg);
      top: calc(50% - 20px);
      position: absolute;
      left: 15px;
    }

    .slick-next,
    .slick-prev {
      opacity: 0;
      z-index: 3;
    }

    &:hover {
      .slick-next,
      .slick-prev {
        opacity: 1;
      }
    }

    .product__link {
      display: block;

      img {
        display: block;
      }
    }

    & > a.product__link:not(:first-child) {
      display: none;
      /* по-умолчанию для ботов яндекс и гугла (без JS загрузки страницы) скрыть доп.фото */
    }
  }

  /*Продублировал. Взял из блока ниже*/

  .product__thumbnails {
    max-width: 100%;
    padding-left: 0px;
    margin-left: 18px;

    .slider-house-projects-nav {
      padding: 0;
      position: relative;
      margin: 0;
      /*margin: 0 7px;*/
      display: flex;

      .slick-list {
        margin: 0 -7px;
      }

      .slick-track {
        width: auto !important;
        transform: none !important;
      }

      .product__thumbnail {
        position: relative;
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 8px;

        img {
          width: 106px;
          height: 60px;
          object-fit: cover;
        }

        &.slick-current::after {
          content: "";
          left: 0px;
          top: 0px;
          bottom: 0px;
          right: 0px;
          position: absolute;
          border-radius: 3px;
          border: 2px solid $blue;
        }
      }

      .slick-next {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        top: 55px;
        position: absolute;
        right: 0;
      }

      .slick-prev {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        color: transparent;
        background: url(../img/arrowrightred.svg);
        transform: rotate(180deg);
        top: 55px;
        position: absolute;
        left: 0;
      }

      .slick-next,
      .slick-prev {
        opacity: 0.4;
      }

      .slick-next:hover,
      .slick-prev:hover {
        opacity: 1;
      }
    }
  }
}

.check-circle-icon {
  background-image: url(../img/icons/check-circle-fill-dark.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 30px; /* Adjust padding to make space for the icon */
  background-position: left center;

  &.check-light {
    background-image: url(../img/icons/check-circle-fill-light.svg);
  }
}

.pricing_package {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 50px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .pricing_package {
    padding: 40px 30px;
  }
}
.pricing_package.active,
.pricing_package:hover {
  background-color: rgb(235 103 83 / 7%);
}
.pricing_package.active .package_title,
.pricing_package:hover .package_title {
  color: var(--primary-color);
}
.pricing_package .heading {
  position: relative;
}
.pricing_package .price-icon {
  position: absolute;
  right: 0;
  top: -10px;
}
.pricing_package .text1,
.pricing_package .text2 {
  color: #0d1c39;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.pricing_package .package_title {
  font-size: 25px;
  margin-bottom: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.list-style1 {
  position: relative;
}
.list-style1 li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  margin-bottom: 20px;
  line-height: 24px;
}
.list-style1 i {
  border-radius: 50%;
  font-size: 8px;
  height: 18px;
  left: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  width: 18px;
}

.timeline-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem 0;

  counter-reset: stage;

  .timeline-splitter {
    width: 4px;
    z-index: 1;
    background-color: rgba(var(--bs-secondary-color-rgb), 0.06);

    height: 100% !important;
    transform: translateX(-50%) !important;
    left: 50% !important;
    top: 0 !important;
    position: absolute !important;
    display: none !important;

    @media (min-width: 768px) {
      display: block !important;
    }
  }

  .timeline-item {
    position: relative;
    width: 50%;
    padding: 0 2.5rem;

    @media (width <= 768px) {
      width: 100%;
      margin-bottom: 1.5rem;
      padding: 1rem;
    }

    &:nth-child(even) {
      text-align: right !important;
    }

    &:nth-child(odd) {
      text-align: left !important;
      margin-left: auto !important;
    }

    .timeline-content {
      position: relative;
      z-index: 2;

      padding: 1.25rem;
      padding-bottom: 0.25rem !important;

      border: 2px solid var(--bs-primary);
      border-radius: 0.625rem;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

      @media (width <= 768px) {
        margin-left: 0;
        text-align: left;
        border: 2px solid var(--bs-primary);
      }

      h4 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
      }

      p {
        line-height: 1.7 !important;
        padding-bottom: 15px;
      }

      .timeline-stage-num {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--bs-primary);
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -1.25rem;
        margin-right: -1.25rem;

        /* Увеличение счетчика для каждого элемента */
        counter-increment: stage;

        &::before {
          content: counter(stage); /* Вставка текущего значения счетчика */
        }
      }

      .timeline-icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff; /* bg-white */
        border: 1px solid var(--bs-primary); /* border border-primary */
        border-radius: 50%; /* rounded-circle */
        display: flex;
        align-items: center;
        justify-content: center;

        width: 3.5rem;
        height: 3.5rem;
        margin-top: -1.25rem;

        img {
          width: 35px;
        }
      }
    }
  }
}

.ticon {
  &.house-foundation {
    background-image: url(../img/icons/house-foundation.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }

  &.geo {
    background-image: url(../img/icons/geo.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
}

.house-project-stages {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
