.reviews
{
	padding: 90px 0 70px;
	background-color: #FCFCFC;

	&--white
	{
		background-color: #fff;
		padding: 0;
		margin-bottom: 50px;

		.review
		{
			border: 1px solid rgba($black, .15);
		}
	}
}

.reviews__title
{
	margin-bottom: 30px;
}

.reviews__more
{
	margin-top: 35px;
	display: flex;
	justify-content: center;

	.g-btn
	{
		width: 207px;
		height: 55px;
	}
}

.reviews__items
{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.review
	{
		flex-basis: calc(50% - 15px);
		max-width: calc(50% - 15px);
		margin-bottom: 15px;
		min-height: 710px;
	}
}

@media (max-width: 1023px)
{
	.reviews
	{
		padding: 65px 0 45px;

		&--white
		{
			padding: 0;
		}
	}

	.reviews__title
	{
		margin-bottom: 33px;
	}

	.reviews__items
	{

		.review
		{
			flex-basis: 100%;
			max-width: 570px;
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 767px)
{
	.reviews
	{
		padding: 40px 0 35px;

		&--white
		{
			padding: 0;
			margin-bottom: 25px;
		}
	}

	.reviews__title
	{
		margin-bottom: 18px;
	}

	.reviews__more
	{
		margin-top: 25px;

		.g-btn
		{
			width: 159px;
			height: 46px;
			font-size: 13px;
		}
	}
}