.page-404-wrap
{
	display: flex;
	justify-content: center;
	margin: 100px 0;
	
	.page-404-image
	{
	    flex-basis: 279px;
		max-width: 279px;
		margin-right: 25px;
		
		span
		{
			font-size: 100px;
			font-weight: 800;
			color: $red;
		}
	}
	
	.page-404-description
	{
	
	}
}