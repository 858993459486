.action
{
	max-width: 100%;
	margin-bottom: 60px;
}

.action__title
{
	margin-bottom: 12px;
}

.action_dates{
	margin: 20px 0;
	
	span {
		background-color: red;
		padding: 7px;
		color: #fff;
		font-weight: bold;
	}
}

.action__content
{
	/*font-size: 22px;
	font-weight: bold;*/

	p
	{
		margin-top: 0;
	}

	picture, img
	{
		&.article__float-img
		{
			margin-top: 0;
			margin-right: 30px;
			margin-bottom: 16px;
			float: left;
			max-width: 270px;
		}
	}

	img
	{
		max-width: 100%;
		height: auto;
	}
	
	iframe
	{
		max-width: 100% !important;
	}
	
	picture
	{
		display: block;
		margin: 25px 0 35px;
		font-size: 0;

		img
		{
			max-width: 100%;
			height: auto;
		}
	}

	strong, b
	{
		@include roboto(bold);
	}

	blockquote
	{
		margin: 35px 0;
		padding: 35px 45px;
		@include roboto(normal, italic);
		font-size: 20px;
		line-height: 1.8;
		border-left: 5px solid $blue;
		background-color: rgba($spindle, .2);
	}

	ul, ol
	{
		padding-left: 25px;
		margin-bottom: 35px;

		li
		{
			/*font-size: 18px;
			margin-bottom: 15px;*/
			line-height: 1.9;
			padding-left: 30px;
			position: relative;

			&:last-child
			{
				margin-bottom: 0;
			}

			&::before
			{
				position: absolute;
				left: 0;
			}
		}
	}

	ul li::before
	{
		content: '';
		top: 10px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $red-click;
	}

	ol
	{
		counter-reset: section;
	}

	ol > li::before
	{
		top: 2px;
		counter-increment: section;
		content: counters(section,".") ".";
		@include roboto(bold);
		font-size: 16px;
		line-height: 1.85;
		color: $red;
	}
	
	ol li ol li
	{
		padding-left: 35px;

		ol li
		{
			padding-left: 50px;
		}
	}

	
}