.review
{
	padding: 24px;
	border-radius: 3px;
	background-color: #fff;
	height: 100%;
}

.review__gallery
{
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
}

.review__gallery-primary
{
	flex-basis: 398px;
	max-width: 398px;
	height: 285px;
	margin-right: 10px;
	position: relative;
	overflow: hidden;
	
	.review__gallery-primary-image {
		display: none;
		
		&.is-active {
			display: block;
		}
	}
}

.review__gallery-secondary
{
	flex-basis: 121px;
	max-width: 121px;
}

.review__gallery-item
{
	width: 100%;
	height: 87px;
	border-radius: 3px;
	overflow: hidden;
	font-size: 0;
	cursor: pointer;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:not(:last-child)
	{
		margin-bottom: 12px;
	}
}

.review__author
{
	margin-bottom: 23px;
	color: rgba($black, .4);
	font-weight: 500;
}

.review__author-name
{
	color: $red;
}

.review__info
{
	margin-bottom: 17px;

	li
	{
		display: flex;
		line-height: 1.6;

		&:not(:last-child)
		{
			margin-bottom: 17px;
		}

		&:first-child
		{
			align-items: center;
		}
	}
}

.review__rate
{
	display: flex;
	align-items: center;
	font-size: 0;
}

.review__rate-star
{
	&:not(:last-child)
	{
		margin-right: 4px;
	}

	&.is-active
	{
		path
		{
			fill: #FFC107;
		}
	}
}

.review__info-type
{
	font-weight: 500;
	margin-right: 8px;
}

.review__info-value
{
	max-width: 375px;

	.g-show-more
	{
		justify-content: flex-start;
	}

	&--long
	{
		display: flex;
		flex-direction: column;

		.g-show-more
		{
			order: 2;

			&.is-active+.review__info-value-text
			{
				-webkit-line-clamp: unset;
			}
		}

	}
}

.review__info-value-text
{
	order: 1;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
	margin-bottom: 15px;
}

.review__text
{
	color: rgba($black, .5);
	font-size: 16px;
	line-height: 1.75;
}

.review__text-value
{
	.g-show-more
	{
		justify-content: flex-start;
	}

	&--long
	{
		display: flex;
		flex-direction: column;

		.g-show-more
		{
			order: 2;

			&.is-active+.review__text-value-text
			{
				-webkit-line-clamp: unset;
			}
		}

	}
}

.review__text-value-text
{
	order: 1;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;  
	overflow: hidden;
	margin-bottom: 15px;
}

@media (max-width: 479px)
{
	.review__gallery
	{
		flex-direction: column;
		margin-bottom: 10px;
	}

	.review__gallery-primary
	{
		flex-basis: 100%;
		max-width: 100%;
		height: 187px;
		margin-right: 0;
		margin-bottom: 5px;
		padding-top: 56%;
	}

	.review__gallery-secondary
	{
		flex-basis: 100%;
		max-width: 100%;
		display: flex;
		justify-content: center;
	}

	.review__gallery-item
	{
		height: 67px;
		margin-bottom: 0;

		&:not(:last-child)
		{
			margin-right: 8px;
		}
	}

	.review__author
	{
		font-size: 14px;
		line-height: 1.45;
		margin-bottom: 15px;
	}

	.review__author-name
	{
		display: block;
		margin-bottom: 2px;
	}

	.review__info
	{
		font-size: 13px;
	}

	.review__info li:not(:last-child)
	{
		margin-bottom: 10px;
	}

	.review__text
	{
		font-size: 13px;
		line-height: 1.6;
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}
}