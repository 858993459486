//-------------------------------------------------------------------
//https://github.com/nico3333fr/jquery-accessible-simple-tooltip-aria
//-------------------------------------------------------------------

/* it will work better with this box-sizing, you may adapt it to your needs */
/*html { box-sizing: border-box; }
*, *:before, *:after {
  box-sizing: inherit;
}*/

/* Tooltip hidden by default */
.simpletooltip[aria-hidden="true"],
.minimalist-simpletooltip[aria-hidden="true"],
.minimalist-left-simpletooltip[aria-hidden="true"] {
  display: none;
}
/* position relative for containers */
.simpletooltip_container,
.minimalist-simpletooltip_container,
.minimalist-left-simpletooltip_container {
  position: relative;
  display: inline;
}

/* tooltip styles */
.simpletooltip,
.minimalist-simpletooltip,
.minimalist-left-simpletooltip {
  position: absolute;
  line-height: 0;
  z-index: 666;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  
  /*display: inline-block;
  z-index: 666;
  width: 10em;
  border-radius: .5em;
  background: rgba( 0, 0, 0, .9 );
  color: #eee;
  padding: .5em;
  text-align: left;
  line-height: 1.3;*/
}
.simpletooltip,
.minimalist-simpletooltip {
  /*right: auto;
  left: 100%;
  margin-left: .5em;*/
}
.minimalist-left-simpletooltip {
  /*right: 100%;
  left: auto;
  margin-right: .5em;*/
}
/* used pseudo-element to make arrows */
/*
.simpletooltip::before,
.minimalist-simpletooltip::before,
.minimalist-left-simpletooltip::before {
  content: '';
  speak: none;
  position: absolute;
  z-index: 666;
  width: 10px;
  height: 10px;
}
.simpletooltip::before,
.minimalist-simpletooltip::before {
  top: .5em;
  left: -10px;
  margin-left: -10px;
  border: 10px solid transparent;
  border-right: 10px solid rgba( 0, 0, 0, .9 );
}
.minimalist-left-simpletooltip::before {
  top: .5em;
  right: -10px;
  margin-right: -10px;
  border: 10px solid transparent;
  border-left: 10px solid rgba( 0, 0, 0, .9 )
}
*/
/* it can be easily adapted in media-queries for tablets/mobile */

/* for this example: mobile */
@media (max-width: 44.375em) {
/*
  .simpletooltip,
  .minimalist-simpletooltip,
  .minimalist-left-simpletooltip  {
    top: 100%;
    left: 50%;
    right: 0;
    margin: 0;
    margin-top: .7em;
    margin-left: -5em;
  }
  .simpletooltip::before,
  .minimalist-simpletooltip::before,
  .minimalist-left-simpletooltip::before  {
    top: -10px;
    right: auto;
    left: 50%;
    margin-left: -5px;
    margin-top: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid rgba( 0, 0, 0, .9 );
  }
*/
}