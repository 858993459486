.price-range {
	padding-top: 10px;
	
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	
	.price-range__slider {
		margin-bottom: 70px;
		height: 5px;
		border: 0;
		box-shadow: none;

		._priceRange {
			border: 0;
			height: 5px;
			margin-bottom: 30px !important;
			background-color: rgba($black, .1);
		}

		.ui-slider-range {
			background-color: #DD2D1F;
			height: 5px;
		}
		.ui-slider-handle
		{
			width: 22px;
			height: 22px;
			right: -10px;
			top: -8px;
			margin-left: -1em;
			background: #FFFFFF;
			border-color: $red;
			border-width: 1px;
			border-radius: 50%;
			cursor: pointer;
			box-shadow: none;

			&:before, &:after
			{
				display: none;
			}
		}

		.price-range__inputs {
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.price-range__input {
				flex-basis: 87px;
				max-width: 87px;
				text-align: center;
				font-weight: 500;
				color: rgba($black, .75);
				font-size: 16px;
				line-height: 1.45;
				height: 40px;
				background-color: rgba($spindle, .3);
				border-radius: 2px;
				border: 0;
			}
			
			.price-range__inputs-devider{
				width: 24px;
				height: 1px;
				background-color: #232323;
				&:last-of-type{
					display:none;
				}
			}
		}

	}
	
}





/*Пережняя версия. Переделал, т.к. слайдер цен в фильтре реализован через mFilter2 -> jqueryUI*/
/*
.price-range
{
	padding-top: 10px;
}

.price-range__slider
{
	margin-bottom: 39px;
	height: 5px;
	border: 0;
	box-shadow: none;
	border-radius: 0;
	background-color: rgba($black, .1);

	.noUi-handle
	{
		width: 22px;
		height: 22px;
		right: -10px;
		top: -8px;
		border-radius: 50%;
		background: #FFFFFF;
		border: 1px solid $red;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: none;

		&:before, &:after
		{
			display: none;
		}
	}

	.noUi-connect
	{
		background-color: $red;
	}
}

.price-range__inputs
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.price-range__input
{
	flex-basis: 87px;
	max-width: 87px;
	text-align: center;
	font-weight: 500;
	color: rgba($black, .75);
	font-size: 16px;
	line-height: 1.45;
	height: 40px;
	background-color: rgba($spindle, .3);
	border-radius: 2px;
	border: 0;
}

.price-range__inputs-devider
{
	width: 24px;
	height: 1px;
	background-color: $black;
}
*/