.payment-example
{
	padding: 100px 0 80px;
}

.payment-example__title
{
	margin-bottom: 50px;
}

.payment-example__wrap
{
	display: flex;
}

.payment-example__img
{
	flex-basis: 340px;
	max-width: 340px;
	margin-right: 85px;

	img
	{
		width: 100%;
	}
}

.payment-example__form
{
	padding-top: 44px;
	position: relative;
	max-width: 320px;
}

.payment-example__form-title
{
	@include roboto(500);
	color: $black;
	font-size: 19px;
	line-height: 1.55;
	margin-bottom: 50px;
}

.payment-example__download
{
	position: absolute;
	right: -264px;
	top: 188px;

	.g-btn
	{
		height: 55px;
		margin-bottom: 15px;
	}

	.g-btn__icon
	{
		margin-right: 0;
		margin-left: 10px;
		width: 18px;
		height: 18px;
	}
}

.payment-example__download-file
{
	font-size: 16px;
	line-height: 1.45;
	color: $red;
	opacity: .5;
	display: flex;
}

.payment-example__download-file-icon
{
	width: 20px;
	height: 20px;
	font-size: 0;
	margin-right: 12px;
	
	img
	{
		width: 100%;
		height: 100%;
	}
}

.payment-example__form-note
{
	color: rgba($black, .3);
	font-size: 13px;
	line-height: 1.35;
	margin-top: 42px;
}

@media (max-width: 1199px)
{
	.payment-example__img
	{
		margin-right: 45px;
	}
}

@media (max-width: 1023px)
{
	.payment-example
	{
		padding: 70px 0;
	}

	.payment-example__title
	{
		margin-bottom: 40px;
	}

	.payment-example__img
	{
		flex-basis: 279px;
		max-width: 279px;
		margin-right: 25px;
	}

	.payment-example__download
	{
		position: static;
		display: flex;
		align-items: center;
		margin-top: 30px;

		.g-btn
		{
			margin-bottom: 0;
			margin-right: 25px;
		}
	}

	.payment-example__form
	{
		padding-top: 0;
		max-width: 348px;

		.g-input
		{
			max-width: 172px;
		}
	}

	.payment-example__form-note
	{
		margin-top: 20px;
	}
}

@media (max-width: 767px)
{
	.payment-example
	{
		padding: 30px 0;
	}

	.payment-example__title
	{
		margin-bottom: 15px;
	}

	.payment-example__form-title
	{
		font-size: 14px;
		line-height: 1.45;
		margin-bottom: 25px;
	}

	.payment-example__img
	{
		display: none;
	}

	.payment-example__download
	{
		margin-top: 20px;

		.g-btn
		{
			height: 46px;
			margin-right: 15px;
		}
	}
}