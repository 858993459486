.video-gallery__title
{
	margin-bottom: 30px;
}

.video-gallery__section
{
	margin-bottom: 30px;
}

.video-gallery__section-title
{
	margin-bottom: 23px;
}

.video-gallery__items
{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.video-gallery__item
{
	flex-basis: calc(100% / 4);
	max-width: calc(100% / 4);;
	padding: 0 15px;
	margin-bottom: 30px;
}

.video-gallery__slider
{
	.video-gallery__item
	{
		flex-basis: 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 0;
	}

	.splide__arrows
	{
		/*top: -62px;*/
	}
}

.video-gallery__item-video
{
	width: 100%;
	/*height: 195px;*/
	margin-bottom: 15px;
	padding-top: 56%;
	position: relative;
}

.video-gallery__item-name
{
	@include roboto(500);
	font-size: 16px;
	line-height: 1.5;
	color: $black;
}

@media (max-width: 1023px)
{
	.video-gallery__title
	{
		margin-bottom: 23px;
	}

	.video-gallery__section-title
	{
		margin-bottom: 18px;
	}

	.video-gallery__item
	{
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
		margin-bottom: 20px;
	}

	.video-gallery__item-video
	{
		/*height: 160px;*/
	}
}

@media (max-width: 767px)
{
	.video-gallery__title
	{
		margin-bottom: 18px;
	}

	.video-gallery__section
	{
		margin-bottom: 13px;
	}

	.video-gallery__section-title
	{
		margin-bottom: 10px;
	}

	.video-gallery__items
	{
		margin: 0 -10px;
	}

	.video-gallery__item
	{
		flex-basis: calc(100% / 2);
		max-width: calc(100% / 2);
		padding: 0 10px;
	}
}

@media (max-width: 479px)
{
	.video-gallery__item
	{
		margin-bottom: 12px;
	}

	.video-gallery__item-video
	{
		/*height: 135px;*/
		margin: 0 auto 10px;
	}

	.video-gallery__item-name
	{
		font-size: 12px;
		line-height: 1.35;
	}

	.video-gallery__slider
	{
		margin-bottom: 30px;

		.video-gallery__item-video
		{
			/*height: 99px;*/
		}

		.splide__arrows
		{
			top: unset;
		}
	}
}