.accordion__item-head {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 22px 31px 18px 23px;
  border: 1px solid;
  margin: 10px 0;

  .accordion__item-head.is-active {
    border-bottom: 1px solid rgba(35, 35, 35, 0.15);
  }

  .accordion__item-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.45;
  }

  &.is-active .accordion__item-icon {
    transform: scaleY(-1);
  }

  .accordion__item-icon {
    font-size: 0;
    transition: 0.3s;
  }
}

.accordion__item-head.is-active + .accordion__item-content {
  /* display: block; */
  max-height: 1500px; /* Достаточно большое значение для контента */
  opacity: 1;
}

.accordion__item-content {
  /*   display: none;
  padding: 20px 20px 25px 23px; */
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.4s ease, opacity 0.4s ease;
}
