.photo-gallery
{
	.g-select
	{
		display: none;
	}
}

.photo-gallery__wrap
{
	display: flex;
	align-items: flex-start;
}

.photo-gallery__nav
{
	flex-shrink: 0;
	flex-basis: 270px;
	max-width: 270px;
	border: 1px solid rgba($black, .15);
	border-radius: 3px;
	margin-right: 30px;
}

.photo-gallery__nav-item
{
	display: block;
	padding: 18px 25px;
	color: $black;
	font-size: 16px;
	line-height: 1.45;
	transition: .135s;

	&:not(:last-child)
	{
		border-bottom: 1px solid rgba($black, .15);
	}

	&.is-active
	{
		background-color: #DDE9F3;

		&:hover, &:focus, &:visited, &:active
		{
			color: $black;
		}
	}

	&:hover
	{
		color: $red;
	}

	&:focus, &:active
	{
		color: $red-click;
	}
}

.photo-gallery__items
{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -9px 20px;
	&--three-rows
	{
		.photo-gallery__item
		{
			flex-basis: calc(100% / 3);
			max-width: calc(100% / 3);
		}
	}
}

.photo-gallery__item
{
	flex-basis: calc(100% / 4);
	max-width: calc(100% / 4);
	padding: 0 9px;
	margin-bottom: 30px;
}

.photo-gallery__item-img
{
	position: relative;
	padding-top: 100%;
	display: block;
	width: 100%;
	/*height: 204px;*/
	margin-bottom: 15px;

	img, picture
	{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		object-fit: cover;
		object-position: center center;
		width: 100%;
		height: 100%;
	}
}

.photo-gallery__item-name
{
	@include roboto(500);
	font-size: 16px;
	line-height: 1.5;
	color: $black;
}

@media (max-width: 1023px)
{
	.photo-gallery
	{
		.g-select
		{
			display: block;
			margin-bottom: 15px;
			width: 248px;
		}
	}

	.photo-gallery__wrap
	{
		flex-direction: column;
	}

	.photo-gallery__nav
	{
		display: none;
	}

	.photo-gallery__items
	{
		margin: 0 -15px 20px;
	}

	.photo-gallery__item
	{
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
		padding: 0 10px;
	}

	.photo-gallery__item-img
	{
		/*height: 218px;*/
	}
}

@media (max-width: 767px)
{
	.photo-gallery__items
	{
		margin: 0 -15px 20px;
	}

	.photo-gallery__item
	{
		flex-basis: calc(100% / 2);
		max-width: calc(100% / 2);
		padding: 0 15px;
	}
}

@media (max-width: 479px)
{
	.photo-gallery__item
	{
		margin-bottom: 15px;
	}

	.photo-gallery__item-img
	{
		/*height: 135px;*/
		margin: 0 auto 10px;
	}

	.photo-gallery__item-name
	{
		font-size: 12px;
		line-height: 1.35;
	}
}