/* special */
@import "special/experiments";
@import "special/others";

/* vendors */
@import "vendors/bootstrap-grid.min";
@import "vendors/bootstrap.adds";
@import "vendors/bootstrap-utils.custom";
@import "vendors/bootstrap-rtl.custom";

@import "vendors/splide-core";
@import "vendors/nouislider";
@import "vendors/slick";
@import "vendors/slick-lightbox";
@import "vendors/jquery-accessible-simple-tooltip-aria";
@import "vendors/pgwslider";
@import "vendors/jquery-ui.min";
@import "vendors/modx-components.import";

/* base */
@import "base/variables";
@import "base/fonts";
@import "base/normalize";
@import "base/typography";
@import "base/controls";

/* layouts */
@import "layouts/default";

/* blocks */
@import "blocks/404";
@import "blocks/header";
@import "blocks/header-nav";
@import "blocks/header-catalog";
@import "blocks/breadcrumbs";
@import "blocks/pagination";
@import "blocks/split";
@import "blocks/compare";
@import "blocks/products";
@import "blocks/product-preview";
@import "blocks/services";
@import "blocks/services-main";
@import "blocks/advantages";
@import "blocks/reviews";
@import "blocks/review";
@import "blocks/youtube-video";
@import "blocks/payment-example";
@import "blocks/suppliers";
@import "blocks/articles";
@import "blocks/article";
@import "blocks/article-preview";
@import "blocks/actions";
@import "blocks/action-preview";
@import "blocks/action";
@import "blocks/brands";
@import "blocks/measure";
@import "blocks/installment";
@import "blocks/calcs";
@import "blocks/calc-roof";
@import "blocks/payment";
@import "blocks/delivery";
@import "blocks/contacts";
@import "blocks/social";
@import "blocks/photo-gallery";
@import "blocks/video-gallery";
@import "blocks/catalog";
@import "blocks/price-range";
@import "blocks/catalog-product";
@import "blocks/product-thumbs";
@import "blocks/product";
@import "blocks/collection-carousel";
@import "blocks/filters";
@import "blocks/table";
@import "blocks/reasons";
@import "blocks/footer";
@import "blocks/certificates";
@import "blocks/accordion";
@import "blocks/tooltips";

@import "pages/index";
@import "pages/house-building";

.w-100 {
  width: 100%;
}

a:active {
  color: inherit;
}

picture {
  display: block;
}

iframe {
  outline: none;
  border: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.hidden {
    overflow: hidden;
  }

  @media (max-width: 1199px) {
    padding-top: 63px;
  }

  @media (max-width: 767px) {
    padding-top: 56px;
  }
}

// for sticky footer
.header,
.footer {
  flex: 0 0 auto;
}

.wrapper {
  flex: 1 0 auto;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 1200px;
  max-width: 100%;
  height: 100%;

  @media (max-width: 1023px) {
    width: 734px;
    padding: 0 10px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 15px;
  }
}

.desktop-only {
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
}

.mobile-only {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.g-show-more-mobile-only {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.g-show-more {
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    display: none;
  }

  &.is-active {
    .g-show-more__icon-more,
    .g-show-more__text-more {
      display: none;
    }

    .g-show-more__icon-less,
    .g-show-more__text-less {
      display: block;
    }
  }
}

.g-show-more__icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  font-size: 0;
  position: relative;
  top: 2px;

  img {
    width: 100%;
    height: 100%;
  }
}

.g-show-more__icon-less,
.g-show-more__text-less {
  display: none;
}

.g-show-more__text {
  color: $red;
  font-size: 15px;
  line-height: 1.45;
  padding-bottom: 3px;
  border-bottom: 1px dashed $red;
}

.more {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  .g-show-more {
    margin-bottom: 15px;
  }

  &.mobile-only {
    display: none;
  }
}

.more__note {
  color: rgba($black, 0.4);
  margin-bottom: 45px;
}

/* Mango Office*/

.button-widget-open {
  /*Всегда СКРЫВАЕМ кнопку заказа обратного звонка от Манго, чтобы она не мешалась, при этом ссылки "Заказать звонок" будут работать и открывать форму заказа звонка Mango*/
  display: none;
}

@media (max-width: 767px) {
  .g-show-more__icon {
    top: 0;
  }

  .g-show-more__text {
    font-size: 13px;
  }

  .more {
    margin-bottom: 30px;

    .g-show-more {
      margin-bottom: 10px;
    }

    &.mobile-only {
      display: flex;
    }
  }

  .more__note {
    font-size: 13px;
    line-height: 1.45;
    margin-bottom: 15px;
  }
}
