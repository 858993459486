.services
{
	margin-bottom: 50px;
	
	.services__title
	{
		margin-bottom: 25px;
	}

	.g-tabs-nav
	{
		margin-bottom: 35px;
	}
	
	.g-select
	{
		display: none;

		.g-select__dropdown
		{
			/*padding: 0;*/
			
			.g-select__option
			{
				padding: 0;
				
				a
				{
					display: block;
					padding: 13px 10px;
				}
			}
		}
	}


	.services__content
	{
		h2
		{
			margin-bottom: 20px;
		}

		p
		{
			line-height: 1.75;
			margin-top: 0;
			margin-bottom: 25px;

			&:last-of-type
			{
				margin-bottom: 20px;
			}
		}

		strong
		{
			font-weight: 500;
		}

		.block
		{
			margin-bottom: 45px;
		}

		.services__img
		{
			display: block;
			width: 750px;
			max-width: 100%;
			font-size: 0;

			img
			{
				max-width: 100%;
				height: auto;
			}
		}

	}


}


@media (max-width: 1023px)
{
	.services
	{
		margin-bottom: 40px;
	}

}

@media (max-width: 767px)
{
	.services
	{
		margin-bottom: 30px;

		.g-select
		{
			display: block;
			margin-bottom: 15px;
		}

		.services__title
		{
			margin-bottom: 15px;
		}
		
		.services__content
		{
			p
			{
				font-size: 13px;
				line-height: 1.6;
			}

			h2
			{
				margin-bottom: 10px;
			}

			section:not(:last-child)
			{
				margin-bottom: 25px;
			}
		}
		
	}


}