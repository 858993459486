.actions {

	.actions__title {
		margin-bottom: 25px;
	}

	.actions__list {
		display: grid;
		/*grid-template-columns: auto auto auto;*/
		grid-template-columns: repeat(auto-fill, minmax(30%,1fr));
		gap: 15px;
	}

	.g-tabs-nav {
		margin-bottom: 35px;
	}
	
	.g-select {
		display: none;

		.g-select__dropdown {
			/*padding: 0;*/
			
			.g-select__option {
				padding: 0;
				
				a {
					display: block;
					padding: 13px 10px;
				}
			}
		}
	}

}



@media (max-width: 1023px)
{
	.actions {

		.actions__title {
			margin-bottom: 15px;
		}

		.actions__list {
			margin-bottom: 20px;
			grid-template-columns: repeat(auto-fill, minmax(45%,1fr));
		}
	}
}

@media (max-width: 767px)
{
	.actions {
		margin-bottom: 30px;

		.actions__title	{
			line-height: 1.2;
			margin-bottom: 15px;
		}

		.actions__list {
			grid-template-columns: repeat(auto-fill, minmax(45%,1fr));
		}

		.g-select {
			display: block;
			margin-bottom: 15px;
		}

		.actions__content {
			p {
				font-size: 13px;
				line-height: 1.6;
			}

			h2 {
				margin-bottom: 10px;
			}

			section:not(:last-child) {
				margin-bottom: 25px;
			}
		}
		
	}
}

@media (max-width: 479px) {
	.actions {
		.actions__list {
			grid-template-columns: 1fr;
		}
	}
}
