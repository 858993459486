/* Собрал все подключаемые из внешних файлов стили и отключил их загрузку в компонентах, 
чтобы ускорить загрузку страницы */

/* Gallery */
.gal-item {
	float: left;
	padding: 3px;
	border: 1px solid #d4d4d4;
	margin: 4px;
	opacity: 0.8;
}

.gal-item:hover {
	opacity: 1.0;
}

/* ms_discount */
.msd_remains .minutes:before,
.msd_remains .seconds:before {
	content: ":";
}

.msd_remains .hours:before {
	content: " ";
}


/* MSearch2 */

#mse2_sort a.active,
#mse2_tpl a.active {
	color: black;
	text-decoration: none;
	cursor: default;
}

#mse2_mfilter .hidden {
	display: none;
}

#mse2_filters .disabled {
	color: #aaa;
}

#mse2_results {
	margin-bottom: 20px;
}

#mse2_filters .mse2_number_slider {
	font-size: .7em;
	margin-bottom: 10px;
}

#mse2_filters .mse2_number_inputs label {
	display: inline-block;
}

#mse2_selected_wrapper {
	min-height: 2em;
	margin: 10px 0;
}

#mse2_selected {
	display: none;
	background: #f9f9f9;
	padding: 5px 0 5px 5px;
	border-radius: 3px;
}

#mse2_selected .mse2_selected_link em {
	color: #000;
}

.mSearchLog,
.mFilterLog {
	width: 100%;
	overflow: auto;
	word-wrap: normal;
	white-space: pre;
}

#mse2_sort .sort span {
	color: #000000;
}

#mse2_sort .sort[data-dir="asc"] span:after {
	content: "↑";
}

#mse2_sort .sort[data-dir="desc"] span:after {
	content: "↓";
}

#mse2_mfilter .btn_more {
	width: 150px;
	display: block;
	margin: auto;
}

#mse2_mfilter .pagination {
	margin: 0;
	padding: 0;
}

#mse2_mfilter .spinner {
	display: none;
	width: 32px;
	height: 32px;
	background: url('spinner.gif') no-repeat;
	background-size: 32px;
}

#mse2_mfilter.loading .spinner {
	display: block;
	margin: 20px auto 10px auto;
}

.form-group .input-sm {
	width: 50%;
	display: inline-block;
}

.mse2-ac-item:hover {
	cursor: pointer;
}

.mse2-ac-item .mse2-ac-weight {
	display: inline-block;
	float: right;
}

.ui-autocomplete-loading {
	background: white url('jquery-ui/images/ui-anim_basic_16x16.gif') right center no-repeat;
}