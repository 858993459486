.catalog-product
{
	width: 100%;
	min-height: 430px;
	border: 1px solid rgba($black, .15);
	border-radius: 3px;
	transition: box-shadow .3s;
	padding-bottom: 20px;

	&:not(:last-child)
	{
		margin-bottom: 20px;
	}

	.product-thumbs
	{
		&:hover
		{
			box-shadow: 0px 2px 10px rgba(35, 35, 35, 0.1);
			border-radius: 3px;
		}
	}
	
}

.catalog-product__wrap
{
	display: flex;
}

.catalog-product__gallery
{
	flex-basis: 340px;
	max-width: 340px;
	width: 340px;
	height: 340px;
	position: relative;

	.catalog-product__image
	{
        position: relative;
		
		/*
		.product__actions-block {
			z-index: 9;
			right: 15px;
			top: 15px;
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: 10px;
			
			.product__action-item {
			    display: inline-flex;
				position: relative;
				height: 28px;
				font-size: 15px;
				font-weight: 500;
				justify-content: center;
				line-height: 14px;
				padding: 0 10px;
				align-items: center;
				background-color: $red;
				border-radius: 2px;
				color: #fff;
				
				&.action-skidka {
					background-color: $red;
				}
				&.action-new {
					background-color: $green;
				}
				&.action-hit {
					background-color: $yellow;
				}
				
				&.more-info {
					svg {
						fill: #ffffff;
						margin-left: 7px;
						width: 18px;
						height: 18px;
						cursor: pointer;
					}
				}
			}
		}
		*/
		
        .catalog-product__action {
            z-index: 3;
            height: 32px;
            right: 15px;
            top: 15px;
            position: absolute;
        }
        .catalog-product__name {
            z-index: 3;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            position: absolute;
            border-radius: 27px;
            padding: 13px 20px;
            background: rgba(255,255,255,0.6);
            color: $black;
			&:empty	{
				display:none;
			}
        }
		.slider-catalog-product-big .catalog-product__link img
		{
			width: 340px;
			height: 340px;
		}

        .slider-for, .slider-catalog-product-big
        {
            .slick-next
            {
                display: block;
                width: 40px;
                height: 40px;
                font-size: 0;
                color: transparent;
                background: url(../img/arrowcircleredright.svg);
                top: calc(50% - 20px);
                position: absolute;
                right: 15px;
            }
            .slick-prev
            {
                display: block;
                width: 40px;
                height: 40px;
                font-size: 0;
                color: transparent;
                background: url(../img/arrowcircleredright.svg);
                transform: rotate(180deg);
                top: calc(50% - 20px);
                position: absolute;
                left: 15px;
            }
            .slick-next, .slick-prev
            {
                opacity: 1;
                z-index: 3;
            }
            .slick-next:hover, .slick-prev:hover
            {
                opacity: 1;
            }
        }
    }
}

.catalog-product__gallery .catalog-product__image .product__actions-block,
.product-preview .product__actions-block {
	z-index: 9;
	right: 15px;
	top: 15px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
	
	.product__action-item {
		display: inline-flex;
		position: relative;
		height: 28px;
		font-size: 15px;
		font-weight: 500;
		justify-content: center;
		line-height: 14px;
		padding: 0 10px;
		align-items: center;
		background-color: $red;
		border-radius: 2px;
		color: #fff;
		
		&.action-skidka {
			background-color: $red;
		}
		&.action-new {
			background-color: $green;
		}
		&.action-hit {
			background-color: $yellow;
		}
		
		&.more-info {
			svg {
				fill: #ffffff;
				margin-left: 7px;
				width: 18px;
				height: 18px;
				cursor: pointer;
			}
		}
	}
}


.catalog-product__color-wrap
{
	position: absolute;
	bottom: 25px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.catalog-product__color
{
	height: 50px;
	line-height: 50px;
	background-color: rgba(#fff, .7);
	border-radius: 27px;
	text-align: center;
	padding: 0 16px;
	transition: .3s;

	strong
	{
		font-weight: 500;
	}
}

.catalog-product__details
{
	/*padding: 35px 39px 20px 33px;*/
	padding: 25px 29px 20px 33px;
	min-height: 340px;
	flex-grow: 1;
	position: relative;
	margin-bottom: 20px;

	&::after
	{
		position: absolute;
		box-sizing: content-box;
		content: '';
		width: calc(100% - 62px);
		bottom: 0;
		display: block;
		border-bottom: 1px solid rgba($black, .15);
	}

	.catalog-product__thumbnails {

		padding: 0;
		position: relative;
		.slider-nav
		{
			margin: 0 -7px;
			max-height: 198px;
			@media (max-width: 479px){
				max-height: 115px;
			}
			margin-bottom: 20px;
			overflow: auto;

			scrollbar-width: thin;
			scrollbar-color: #DD2D1F #DEDEDE;
			// Works on Chrome, Edge, and Safari
			&::-webkit-scrollbar {
			  width: 3px;
			}
			&::-webkit-scrollbar-track {
			  background: #DEDEDE;
			}
			&::-webkit-scrollbar-thumb {
			  background-color: #DD2D1F;
			}				
		}
        .catalog-product__thumbnail
        {
			float: left;
			margin: 0 7px 6px 0px;
			line-height: 0;
			position: relative;
			
			@media (max-width: 767px)
			{
				margin: 0 5px 5px 0px;
			}

			img
			{
				width: 100%;
			}
		}
/*	
		.slider-nav
		{
			padding: 0;
			position: relative;
			.slick-list
			{
				margin: 0 -7px;
				max-height: 199px;
				@media (max-width: 479px){
					max-height: 115px;
				}
				margin-bottom: 20px;
				overflow: auto;

				scrollbar-width: thin;
				scrollbar-color: #DD2D1F #DEDEDE;
				// Works on Chrome, Edge, and Safari
				&::-webkit-scrollbar {
				  width: 3px;
				}
				&::-webkit-scrollbar-track {
				  background: #DEDEDE;
				}
				&::-webkit-scrollbar-thumb {
				  background-color: #DD2D1F;
				}				
			}
			.slick-track
			{
				width: auto!important;
				transform: none!important;
			}
        }
        .catalog-product__thumbnail
        {
            &.slick-current::after
            {
                content:"";
                left: 0px;
                top: 0px;
                bottom: 0px;
                right: 0px;
                position: absolute;
                border-radius: 3px;
                border: 2px solid $blue;
            }
        }
*/
		.slider-nav
		{
			.catalog-product__thumbnail:hover
			{
				a::before
				{
					position: absolute;
					content:"";
					width: 100%;
					height: 100%;
					background-color: transparent;
					border: 2px solid $blue;
					border-radius: 3px;
/*
					left: 0px;
					top: 0px;
					bottom: 0px;
					right: 0px;
					border-radius: 3px;
					border: 2px solid $blue;
					*/
				}
			}
		}

    }
}

.catalog-product__title
{
	line-height: 1.45;
	margin-bottom: 30px;
	/*max-width: 374px;*/
	@include roboto(900);
	font-size: 30px;

	a
	{
	    color: $black;
	    display: inline-block;
	}
}

.catalog-product__slider
{
	width: 100%;
}

.catalog-product__price-n-chars
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.product-price__current
{
	font-weight: 500;
	color: $red;
	line-height: 1.45;
	display: flex;
	align-items: center;
}

.product-price__current-val
{
	font-size: 32px;
	margin: 0 9px;
}

.product-price__old
{
	font-size: 17px;
	line-height: 20px;
	color: rgba($black, 0.6);
	position: relative;
    display: inline-block;
	/*text-decoration: line-through;
	text-decoration-color: $red;*/
	
	&:after {
		content: "";
		background-color: $red;
		display: inline-block;
		position: absolute;
		height: 1.4px;
		left: 0;
		top: 45%;
		transform: rotate(-9deg);
		width: 100%;
	}
	
}

.catalog-product__char
{
	&:not(:last-child)
	{
		margin-bottom: 9px;
	}
}

.catalog-product__btns
{
	display: flex;
	justify-content: flex-end;
	padding-right: 29px;

	.g-btn-transp
	{
		margin-right: 20px;
	}

	.g-btn
	{
		height: 50px;
	}

	.g-btn__icon
	{
		width: 17px;
		height: 17px;
		margin-right: 9px;
	}
}

.catalog-product-medium {
	flex-basis: 25%;
	max-width: 25%;
	/*box-shadow: inset -1px 0 0 0 rgba(35,35,35,.15), inset 0 -1px 0 0 rgba(35,35,35,.15), -1px -1px 0 0 rgba(35,35,35,.15), -1px 0 0 0 rgba(35,35,35,.15), 0 -1px 0 0 rgba(35,35,35,.15);*/
	
	.product-preview {
		border-radius: 0;
	}
}



@media (min-width: 1099px)
{
	.catalog-product
	{
		&:hover
		{
			box-shadow: 0px 3px 25px rgba(103, 186, 242, 0.4);

			.catalog-product__slider .splide__arrows, .catalog-product__color
			{
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.catalog-product__slider .splide__arrows, .catalog-product__color
	{
		opacity: 0;
		visibility: hidden;
	}
}





@media (max-width: 1099px)
{
/*
	.catalog-product__gallery
	{
		width: 280px;
		height: 280px;
		flex-basis: 280px;
		max-width: 280px;
	}
*/
	.catalog-product__color-wrap
	{
		bottom: 12px;
	}

	.catalog-product__color
	{
		height: 42px;
		line-height: 42px;
	}

	.catalog-product__slider .splide__arrows
	{
		padding: 0 15px;
	}
}
@media (max-width: 1023px)
{
	.catalog-product-medium {
		flex-basis: 33%;
		max-width: 33%;
	}
}

@media (max-width: 767px)
{
	.catalog-product
	{
		.catalog-product__gallery
		{
			width: 290px;
			height: 290px;
			flex-basis: 290px;
			max-width: 290px;
			margin: 0 auto;

			.splide__arrow svg circle
			{
				fill: #FFFFFF;
			}

			.slider-catalog-product-big .catalog-product__link img
			{
				width: 290px;
				height: 290px;
			}
		}
	}

	.catalog-product__wrap
	{
		flex-wrap: wrap;
	}

	.catalog-product__btns
	{
		flex-direction: column;
		align-items: center;
		padding: 0 14px 14px;

		.g-btn-transp
		{
			margin-right: 0;
			margin-bottom: 5px;
		}

		.g-btn-transp, .g-btn
		{
			width: 100%;
			height: 43px;
		}

		.g-btn-compare
		{
			order: 3;
			margin-top: 15px;
			margin-right: 0;
		}
	}

	.catalog-product__details
	{
		padding: 12px 14px;
		min-height: unset;

		&::after
		{
			width: calc(100% - 28px);
		}
	}

	.catalog-product__title
	{
		margin-bottom: 12px;
	}

	.catalog-product__price
	{
		margin-right: 15px;
	}

	.product-price__current
	{
		font-size: 14px;
		flex-shrink: 0;
		white-space: nowrap;
	}

	.product-price__current-val
	{
		font-size: 28px;
	}

	.product-price__old
	{
		font-size: 15px;
		line-height: 18px;
	}

	.catalog-product__char
	{
		font-size: 13px;
		line-height: 15px;
	}

	.catalog-product__char:not(:last-child)
	{
		margin-bottom: 7px;
	}
}


@media (max-width: 560px)
{
	.catalog-product-medium {
		flex-basis: 50%;
		max-width: 50%;
	}
}

	