.g-tabs-nav__btn2 {
	margin-right: 15px;
	display: inline-block;
	cursor: pointer;
	span {
		display: block;
		margin-top: 20px;
		text-align: center;
		font-weight: 500;
		margin-bottom: 20px;
	}
	.g-tabs-nav__btn2-img {
		position: relative;
		display: block;
		img {
			display: block;
			margin-bottom: 15px;
			width: 100px;
			height: 100px;
			object-fit: cover;
		}
	}
	&.is-active {
		span {
			color: $blue;
		}
	}
	&.is-active .g-tabs-nav__btn2-img:before {
		display: block;
		content:"";
		position: absolute;
		top: -7px;
		bottom: -7px;
		right: -7px;
		left: -7px;
		border: 2px solid $blue;
		border-radius: 3px;
	}
}

.products
{
	padding: 75px 0 100px;

	.product-preview
	{
		height: 100%;
	}

	.g-select
	{
		margin-bottom: 8px;
	}
}
.products__nav
{
	display: flex;
	margin-bottom: 35px;
}

.products__nav-item
{
	@include roboto(500);
	font-size: 22px;
	line-height: 1.45;
	color: rgba($black, .3);
	padding-bottom: 5px;
	border-bottom: 3px solid transparent;
	cursor: pointer;
	transition: .135s;

	&:not(:last-child)
	{
		margin-right: 40px;
	}

	&:hover
	{
		color: $black;
	}

	&.is-active
	{
		color: $black;
		font-weight: bold;
		border-color: $red;
	}
}

.products__tab
{
	height: 0;
	overflow: hidden;

	&.is-active
	{
		height: auto;
		overflow: visible;
	}
}

.surface__tab
{
	height: 0;
	overflow: hidden;

	&.is-active
	{
		height: auto;
		overflow: visible;
	}
}

.products__carousel
{
	@media (min-width: 1024px)
	{
		margin: 0 -15px;

		&.splide
		{
			visibility: visible;
		}

		.splide__list, .splide__track
		{
			width: 100%;
		}

		.splide__arrows
		{
			display: none;
		}

		.splide__list
		{
			display: flex;
			flex-wrap: wrap;
		}

		.splide__track
		{
			overflow: visible;
		}

		.splide__slide
		{
			flex-basis: 25%;
			max-width: 25%;
			margin-bottom: 20px;
			padding: 0 15px;
		}
	}
}

@media (max-width: 1023px)
{
	.products
	{
		padding: 50px 0 65px;
	}
}

@media (max-width: 767px)
{
	.products
	{
		padding: 20px 0 40px;
	}

	.products__nav
	{
		display: none;
	}
}