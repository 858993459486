button {
	outline: none;
	border: 0;
}

.g-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	padding: 0 26px;
	background-color: $red;
	color: #fff;
	@include roboto(500);
	font-size: 16px;
	line-height: 19px;
	border-radius: 3px;
	transition: .3s;

	&:hover {
		background-color: $red-hover;
	}

	&:focus {
		background-color: $red-click;
	}

	.g-btn-undertitle {
		color: #ffffff9e;
		display: block;
		font-size: 10px;
		font-weight: normal;
	}
}

.g-btn__icon {
	flex-shrink: 0;
	width: 22px;
	height: 22px;
	margin-right: 15px;
}

.g-btn-transp {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding: 0 26px;
	background-color: #fff;
	color: $blue;
	@include roboto(500);
	font-size: 16px;
	line-height: 19px;
	border-radius: 3px;
	border: 2px solid $blue;
	transition: .3s;

	&:hover {
		background-color: $blue;
		color: #fff;
	}

	&:focus {
		color: #fff;
		border-color: #52A5DC;
		background-color: #52A5DC;
	}

	&:disabled {
		border-color: #BABABA;
		color: #BABABA;
	}

	&--arrow {
		min-width: 218px;
		text-align: left;
		position: relative;
		height: 55px;
		justify-content: flex-start;

		&:hover {
			background-color: transparent;
			color: #52A5DC;
		}

		&:focus {
			color: #52A5DC;
			background-color: transparent;
		}

		&::after {
			display: block;
			content: '';
			position: absolute;
			right: 20px;
			top: 18px;
			width: 16px;
			height: 16px;
			background-image: url('../img/icons/transp-arrow.svg');
			background-repeat: no-repeat;
			background-size: cover;
			transition: .3s;
		}

		&.is-active {
			&::after {
				transform: scale(1, -1);
			}
		}
	}
}


.g-btn-blue {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	padding: 0 26px;
	background-color: $blue;
	color: #fff;
	@include roboto(500);
	font-size: 16px;
	line-height: 19px;
	border-radius: 3px;
	transition: .3s;

	&:hover {
		background-color: $blue-hover;
	}

	&:focus {
		background-color: $blue-click;
	}
}


.g-btn-compare {
	display: flex;
	align-items: center;
	color: $black;
	margin-right: 20px;
	transition: .2s;

	&:hover {
		color: $blue;

		.g-btn-compare__icon {
			opacity: 1;

			path {
				fill: $blue;
			}
		}
	}

}

.g-btn-compare__icon {
	margin-right: 10px;
	opacity: .5;

	path {
		transition: .2s;
	}
}

.g-search {
	display: block;
	width: 270px;
	height: 52px;
	position: relative;

	&--has-value {
		.g-search__btn {
			right: 56px;

			&--clear {
				display: block;
				right: 25px;
			}
		}

		.g-search__input {
			padding-right: 80px;
		}
	}
}

.g-search__input {
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 50px;
	border: 1px solid rgba($blue, .3);
	border-radius: 3px;
	@include roboto(500);
	color: $black;
	font-size: 16px;
	line-height: 19px;

	&::-webkit-input-placeholder {
		/* Edge */
		color: rgba($black, .35);
		@include roboto(normal, italic)
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: rgba($black, .35);
		@include roboto(normal, italic)
	}

	&::placeholder {
		color: rgba($black, .35);
		@include roboto(normal, italic)
	}
}

.g-search__btn {
	position: absolute;
	height: 18px;
	width: 18px;
	top: 17px;
	right: 25px;
	transition: .3s;
	font-size: 0;

	&--clear {
		display: none;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.g-tabs-nav {
	display: inline-flex;
	border-radius: 3px;
	background-color: rgba($spindle, .3);
}

.g-tabs-nav__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 63px;
	@include roboto(500);
	color: $black;
	font-size: 16px;
	line-height: 19px;
	border: 1px solid transparent;
	border-radius: 3px;
	position: relative;
	padding: 0 67px;
	overflow: hidden;
	transition: color .3s;
	cursor: pointer;

	@media (max-width: 1023px) {
		padding: 0 60px;
	}

	&.is-active {
		background-color: #fff;
		border-color: rgba(35, 35, 35, 0.07);

		&::after {
			background-color: $red;
			width: 100%;
		}
	}

	&:hover {
		color: $red-hover;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		width: 0;
		height: 3px;
		background-color: transparent;
		transition: .3s;

	}
}

.g-select {
	position: relative;
	width: 100%;
	max-width: 100%;

	&.is-active {
		.g-select__btn::after {
			transform: scale(1, -1)
		}

		.g-select__dropdown {
			visibility: visible;
			transform: scale(1, 1);
			z-index: 5;
		}
	}
}

.g-select__btn {
	display: flex;
	align-items: center;
	width: 100%;
	height: 43px;
	padding: 0 45px 0 25px;
	background-color: #57ACE6;
	@include roboto(500);
	color: #fff;
	border-radius: 2px;
	font-size: 14px;
	line-height: 1.2;

	&::after {
		display: block;
		content: '';
		position: absolute;
		right: 20px;
		top: 13px;
		width: 16px;
		height: 16px;
		background-image: url('../img/icons/select-arrow.svg');
		background-repeat: no-repeat;
		background-size: cover;
		transition: .3s;
	}
}

.g-select__dropdown {
	position: absolute;
	top: 100%;
	width: 100%;
	padding: 0 15px;
	border-radius: 0px 0px 2px 2px;
	overflow: hidden;
	visibility: hidden;
	background-color: #DDE9F3;
	transform: scale(1, 0);
	transform-origin: top center;
	transition: 0.2s ease;
}

.g-select__option {
	padding: 13px 10px;
	border-bottom: 1px solid rgba($black, .15);
	@include roboto(500);
	font-size: 14px;
	line-height: 1.2;
	color: $black;
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}

	a {
		color: $black;
	}
}

.g-input {
	display: flex;
	flex-direction: column;
	@include roboto();
	color: $black;
	font-size: 16px;
	line-height: 19px;
	position: relative;

	&.g-input-compact {
		display: block;
		margin: 0 5px;

		input {
			padding: 0;
			height: auto;
		}
	}

	&.error {
		.g-input__field {
			color: $red;
			border-color: $red;
		}

		.g-input__error-msg {
			visibility: visible;
			opacity: 1;
			bottom: -28px;
		}
	}
}

.g-input__field {
	font-weight: 500;
	border: 0;
	padding-bottom: 15px;
	border-bottom: 1px solid rgba($black, .3);

	&::placeholder {
		color: rgba($black, .4);
	}

	&:focus {
		border-color: $blue;
	}
}

.g-input__name {
	margin-bottom: 30px;
}

.g-input__error-msg {
	opacity: 0;
	visibility: hidden;
	font-style: italic;
	font-size: 15px;
	line-height: 18px;
	color: $red;
	position: absolute;
	bottom: 0;
	transition: .2s;
}

.g-checkbox {
	flex-shrink: 0;
	/*	width: 20px;
	height: 20px;*/
	width: 17px;
	height: 17px;
	position: relative;
	margin-right: 8px;
}

.g-checkbox__input {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;

	&:checked {
		&+.g-checkbox__custom {
			background-image: url('../img/icons/checked.svg');
			background-repeat: no-repeat;
			background-position: center;
			opacity: 1;
		}
	}
}

.g-checkbox__custom {
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
	padding: 0;
	opacity: 0.5;
	border: 1px solid $spindle;
	box-sizing: border-box;
	border-radius: 2px;
	transition: opacity .2s;

	&:hover {
		opacity: 1;
	}
}

.g-checkbox__radio {

	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}
}

.g-checkbox__radio:checked+label,
.g-checkbox__radio:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}

.g-checkbox__radio:checked+label:before,
.g-checkbox__radio:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 22px;
	height: 22px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
}

.g-checkbox__radio:checked+label:after,
.g-checkbox__radio:not(:checked)+label:after {
	content: '';
	width: 14px;
	height: 14px;
	background: #DD2D1F;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.g-checkbox__radio:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.g-checkbox__radio:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.share__popup {
	display: none;
}

.share__popup {

	&.is-active {
		display: block;
	}

}

/* Tooltip text */
.tooltip {
	width: 150px;
	background-color: white;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	box-shadow: 0 0 15px rgb(0 0 0 / 12%);
	border-radius: 2px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;

	&.tooltip-bottom {
		width: 150px;
		top: 100%;
		left: 50%;
		margin-left: -75px;
		/* Use half of the width (120/2 = 60), to center the tooltip */
	}

	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border: 11px solid transparent;
		border-bottom-color: #fff;
		left: 50%;
		top: -16px;
		margin-left: -11px;
	}

	.tooltip-container {
		/*padding: 20px 20px 15px 23px;*/
		padding: 10px;
	}

}

.share__popup {

	.share__list {
		list-style-type: none;
		display: inline-block;
		vertical-align: top;

		.share__item {
			display: inline-block;
			padding: 0;
			margin: 2px 4px 5px 0;
			vertical-align: top;
			position: relative;


			&.share__item-service-vk .share__badge {}

			&.share__item-service-ok .share__badge {
				background-position: -43px -3px;
			}

			&.share__item-service-moymir .share__badge {
				background-position: -123px -3px;
			}

			&.share__item-service-twitter .share__badge {
				background-position: -3px -43px;
			}

			&.share__item-service-viber .share__badge {
				background-position: -283px -4px;
			}

			&.share__item-service-skype .share__badge {
				background-position: -284px -84px;
			}

			&.share__item-service-telegram .share__badge {
				background-position: -284px -123px;
			}

		}
	}


	.share__badge {
		background: url(/assets/img/icons/social.svg) -3px -3px no-repeat;
		transition: opacity .5s ease;
		display: inline-block;
		vertical-align: top;

		width: 28px;
		height: 28px;
		border-radius: 3px;

		.share__title {
			display: none;
		}
	}

	.share__item .share__icon {
		height: 24px;
		width: 24px;
		background-size: 24px 24px;
	}

	.share__item-service-vk .share__icon {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.878 17.304c-5.411 0-8.695-3.755-8.823-9.994h2.74c.086 4.583 2.171 6.528 3.77 6.925V7.31h2.627v3.954c1.542-.17 3.155-1.97 3.698-3.954h2.584c-.414 2.441-2.17 4.24-3.412 4.983 1.242.6 3.24 2.17 4.011 5.01h-2.84c-.6-1.898-2.07-3.369-4.04-3.569v3.57h-.315Z' fill='%23fff'/%3E%3C/svg%3E");
	}


}

.tooltip-block>.close-popup-btn {
	position: absolute;
	top: 2px;
	right: 5px;
	cursor: pointer;
}


.dedicated__block {
	margin: 30px 0;
	max-width: 60%;

	.dedicated__block-title {
		border: 3px solid $red;
		border-top: none;
		border-bottom: none;
		border-right: none;
		margin: 30px 0 30px 0;
		padding: 20px 0 20px 30px;

		span {
			font-size: 50px;
			font-weight: bold;
			line-height: 1.2;
		}

	}

	.dedicated__block-description {

		span {
			font-weight: bold;
			font-size: 22px;
		}

	}

}

.blinked-text {
	animation: blink 1s linear infinite;

	a:visited {
		animation: none;
	}
}

a.blinked-text:visited {
	animation: none;
}

@keyframes blink {
	0% {
		opacity: .3;
	}


	50% {
		opacity: 1;
	}

	100% {
		opacity: .3;
	}
}

.g-select-styled {
	position: relative;
	display: block;
	/*max-width: 400px;
    min-width: 180px;*/
	margin: 0 auto;
	background-color: #fff;
	z-index: 10;

	select {
		cursor: pointer;
		border: none;
		outline: none;
		background: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		border: 1px solid $blue;
		margin: 0;
		display: block;
		width: 100%;
		padding: 5px 25px 5px 7px;
		font-size: 16px;
		color: $blue;
	}

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		width: 19px;
		height: 100%;
		background: no-repeat url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M10.1781 14.4533L18.7194 5.90905C19.0934 5.53414 19.0934 4.92672 18.7194 4.55086C18.3455 4.17594 17.7381 4.17594 17.3641 4.55086L9.50043 12.4173L1.63678 4.5518C1.26281 4.17689 0.65539 4.17689 0.280476 4.5518C-0.0934918 4.92672 -0.0934918 5.53509 0.280476 5.91L8.82177 14.4542C9.19187 14.8234 9.80884 14.8234 10.1781 14.4533Z' fill='%2357ACE6'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='19' height='19' fill='white' transform='translate(0 19) rotate(-90)'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
		content: "";
		text-align: center;
		color: $blue;
		z-index: -1;
		background-position-y: 6px;
		margin-right: 6px;
	}
}



@media (max-width: 1024px) {
	.dedicated__block {
		max-width: 80%;
	}
}

@media (max-width: 767px) {
	.dedicated__block {
		max-width: initial;

		.dedicated__block-title {
			span {
				font-size: 30px
			}
		}

		.dedicated__block-description {
			span {
				font-size: 18px;
			}
		}
	}

	.g-search {
		height: 40px;

		&--has-value {
			.g-search__btn {
				right: 46px;

				&--clear {
					display: block;
					right: 12px;
				}
			}

			.g-search__input {
				padding-right: 70px;
			}
		}
	}

	.g-search__input {
		padding-left: 15px;
		font-size: 13px;
		line-height: 15px;
	}

	.g-search__btn {
		width: 16px;
		height: 16px;
		right: 16px;
		top: 12px;
	}

	.g-tabs-nav {
		display: none;
	}

	.g-input {
		font-size: 14px;
		line-height: 16px;
	}

	.g-input__name {
		margin-bottom: 25px;
	}

	.g-btn,
	.g-btn-transp,
	.g-btn-compare {
		padding: 0 22px;
		font-size: 13px;
		line-height: 15px;
		height: 46px;
	}
}