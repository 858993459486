.footer {
  background-color: #fcfcfc;
  padding: 70px 0 30px;
}

.footer__columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.footer__column {
  margin-right: 90px;

  &:nth-last-child(2) {
    margin-right: 0;
    margin-left: auto;

    .footer__column-list-item:not(.footer__column-list-item--mobile) {
      display: flex;
    }
  }

  &--mobile {
    display: none;
  }
}

.footer__column-title {
  line-height: 19px;
  @include roboto(500);
  margin-bottom: 30px;
}

.footer__column-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 278px;
  max-height: 278px;
}

.footer__column-list-item {
  margin-bottom: 20px;
  color: rgba($black, 0.8);

  a {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    color: inherit;
    transition: 0.135s;

    &:hover {
      color: $black;
    }
  }

  /*   &:nth-child(n + 7) {
    margin-left: 60px;
  } */

  &--mobile {
    display: none;
  }
}

.footer__column-list-item-icon {
  margin-right: 12px;
  display: inline-block;
  font-size: 0;
}

.footer__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-top: 1px solid rgba($black, 0.1);
  border-bottom: 1px solid rgba($black, 0.1);
  margin-bottom: 60px;
}

.footer__links-item {
  @include roboto(500);
  color: $black;
  line-height: 19px;
  margin-right: 10px;
  transition: 0.135s;

  &:hover {
    color: $red;
  }
}

.footer__links-social-item {
  font-size: 0;

  &:not(:last-child) {
    margin-right: 17px;
  }
}

.footer__bottom {
  max-width: 1079px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__logo {
  width: 218px;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
  }
}

.footer__copy {
  font-size: 12px;
  line-height: 1.45;
  color: rgba($black, 0.3);

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.footer__about {
  max-width: 564px;
  font-size: 12px;
  color: rgba($black, 0.3);
  line-height: 1.45;
  margin-right: 20px;
}
.footer__callback {
  display: flex;

  .g-link__icon {
    margin-left: 0;
    margin-right: 10px;
  }
}

@media (max-width: 1199px) {
  .footer__column {
    margin-right: 75px;
  }

  /* .footer__column-list-item:nth-child(n + 7) {
    margin-left: 35px;
  } */
}

@media (max-width: 1023px) {
  .footer {
    background-color: #fcfcfc;
    padding: 55px 0 17px;
  }

  .footer__columns {
    flex-wrap: wrap;
  }

  .footer__column {
    margin-bottom: 40px;

    &--mobile {
      display: block;
    }
  }

  .footer__column-list {
    height: auto;
    max-height: unset;
    display: block;
  }

  /* .footer__column-list-item:nth-child(n + 7) {
    margin-left: 0;
  } */

  .footer__column-list-item--mobile {
    display: flex;
    a {
      font-size: 14px;
      color: #dd2d1f;
    }
  }

  .footer__column:nth-last-child(2) {
    margin-left: 0;
  }

  .footer__column {
    max-width: 50%;
    flex-basis: 50%;
    margin-right: 0;
    justify-content: space-between;

    &:nth-child(1) {
      order: 1;
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 2;
    }

    &:nth-child(4) {
      order: 4;
    }
  }

  .footer__links {
    .footer__links-social {
      display: none;
    }
  }

  .footer__callback {
    display: none;
  }

  .footer__about {
    margin-right: 0;
    max-width: 441px;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 35px 0 10px;
  }

  .footer__columns {
    margin-bottom: 25px;
  }

  .footer__column {
    font-size: 13px;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 30px;

    &:last-child {
      /*margin-bottom: 0;*/
    }

    &:nth-child(2) {
      order: 2;
    }

    &:nth-child(3) {
      order: 3;
    }
  }

  .footer__column-title {
    font-size: 15px;
    margin-bottom: 17px;
  }

  .footer__links {
    padding: 25px 0 20px;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .footer__column-list-item {
    margin-bottom: 15px;
    a {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .footer__links-item {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 13px;
    }
  }

  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__logo {
    width: 198px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .footer__copy {
    line-height: 1.65;
  }
}

@media (max-width: 1023px) {
  .footer__column-flex {
    .footer__column-list {
      display: flex;
      flex-direction: column;
      flex-flow: row wrap;

      .footer__column-list-item {
        margin: 0px 16px 16px 0px;
      }
    }
  }
}
