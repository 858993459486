.brands {
	margin-bottom: 50px;
	
	
	.brands__list {
		/*display: grid;
		grid-template-columns: repeat(auto-fill, minmax(23%,1fr));
		gap: 15px;*/
	}
	
	.brands_letter {
		padding: 20px 0;
		font-size: 22px;
		font-weight: bold;
	}
		
	.brands_section {
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(auto-fill,minmax(270px,1fr));
		
		.brands_item {
			width: 270px;
			min-height: 124px;
			display: block;
			color: initial;
			text-align: center;
			vertical-align: middle;
			
			img {
				max-width: 270px;
			    border: 2px solid #f2f2f2;
				vertical-align: middle;
				display: inline-block;				
				/*
				position: relative;
				
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					
				}
				&:hover:before {
					background-color: #f2f2f285;
				}
				*/
			}
			
			.brands_item-caption {
				padding-top: 15px;
			}

			
		}
		
	}
}

