.social__title
{
	line-height: 1.45;
	margin-bottom: 20px;
}

.social__list
{
	width: 192px;
	margin: 0 -12px;
	height: 100px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.social__item
{
	margin: 0 12px 20px;
	flex-basis: 40px;
	max-width: 40px;
	height: 40px;
	flex-shrink: 0;
	font-size: 0;

	.social-icon
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

@media(max-width: 767px)
{
	.social__list
	{
		width: 100%;
		height: 85px;
		margin: 0;
	}

	.social__item
	{
		width: 35px;
		height: 35px;
		margin: 0 0px 15px;
	}
}