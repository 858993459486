.product-preview
{
	&.colors-selection:hover {
		width: calc(100% + 92px);
		margin-left: -92px;
		padding-left: 114px;
		.product-preview__additional {
			width: calc(100% - 90px);
			left: 91px;
		}
		.product-preview__additional-colors {
			left: -1px;
		}
	}
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba($black, .15);
	border-radius: 3px;
	padding: 20px 22px;
	position: relative;
	z-index: 1;
	transition: .3s;

	.g-btn
	{
		width: 100%;
		height: 43px;
		flex-shrink: 0;
	}

	.g-btn-transp
	{
		width: 100%;
		height: 43px;
		font-size: 15px;
		flex-shrink: 0;
	}
	
	&:hover
	{
		
		@media (min-width: 768px)
		{
			z-index: 5;
			box-shadow: 0px 6px 20px rgba(35, 35, 35, 0.12);
			border-color: transparent;

			.product-preview__additional
			{
				visibility: visible;
				opacity: 1;
			}
			.product-preview__additional-colors
			{
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

.product-preview__img
{
	width: 100%;
	/*height: 226px;*/
	font-size: 0;
	margin-bottom: 18px;
	padding-top: 100%;
	position: relative;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px solid rgba(35,35,35,.05);
	}
}

.product__action, .catalog-product__action
{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 12px;
	right: 12px;
	height: 27px;
	padding: 0 12px;
	font-weight: 500;
	font-size: 15px;
	line-height: 14px;
	color: #fff;
	text-transform: uppercase;
	background-color: $red;
	border-radius: 2px;

	&--sale
	{
		background-color: $blue;
	}

	&--hit
	{
		background-color: #F8BA00;
	}

	&--new
	{
		background-color: #1EC15F;
	}
}

.product-preview__title
{
	font-weight: 500;
	font-size: 16px;
	line-height: 1.45;
	color: $black;
	margin-bottom: 22px;
	transition: .135s;
	
	&:focus, &:visited, &:active
	{
		color: $black;
	}

	&:hover
	{
		color: $red;
	}
}

.product-preview__price
{
	margin-top: auto;
	margin-bottom: 21px;
}

.product-preview__price-old
{
	font-size: 16px;
	line-height: 19px;
	color: rgba($black, 0.6);
	position: relative;
    display: inline-block;
	/*text-decoration: line-through;*/
	margin-bottom: 7px;

	&:after {
		content: "";
		background-color: $red;
		display: inline-block;
		position: absolute;
		height: 1.4px;
		left: 0;
		top: 45%;
		transform: rotate(-9deg);
		width: 100%;
	}
}

.product-preview__price-new
{
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: $red;
}

.product-preview__additional
{
	visibility: hidden;
	position: absolute;
	left: -1px;
	bottom: 0;
	background-color: #fff;
	width: calc(100% + 2px);
	padding-bottom: 25px;
	transition: .3s;
	opacity: 0;
	padding: 0 18px 20px;
	transform: translateY(calc(100% - 13px));
	border-radius: 0 0 3px 3px;
	box-shadow: 0px 17px 20px rgba(35, 35, 35, 0.12);
}
.product-preview__additional-colors
{
	z-index:-1;
	visibility: hidden;
	position: absolute;
	left: -83px;
	top: -1px;
	background-color: $colorselectorback;
	width: 82px;
	transition: .3s;
	opacity: 0;
	padding: 0 5px 0 10px;
	border-radius: 0 0 3px 3px;
	/*box-shadow: 0px 17px 20px rgba(35, 35, 35, 0.12);*/
	.slick-vertical {
		padding: 53px 0 48px;
		img.active {
			border: 1px solid $red;
			cursor: pointer;
		}
		img {
			width: 62px;
			height: 62px;
			object-fit: cover;
			object-position: center center;
			margin: 0px 0 5px;
			transition: 0.3s;
			border-radius: 3px;
		}
		.slick-prev {
			font-size: 0;
			color: transparent;
			width: 62px;
			height: 18px;
			background: url(../img/arrowdown.svg) center center no-repeat;
			transform: rotate(180deg);
			display: block;
			top: 15px;
			position: absolute;
		}
		.slick-next {
			font-size: 0;
			color: transparent;
			width: 62px;
			height: 18px;
			background: url(../img/arrowdown.svg) center center no-repeat;
			display: block;
			bottom: 15px;
			position: absolute;
		}
	}
}
.product-preview__thickness-title
{
	font-size: 16px;
	line-height: 19px;
	color: rgba($black, .4);
	margin-top: 22px;
	margin-bottom: 10px;
}

.product-preview__thickness
{
	display: flex;
	font-size: 16px;
	line-height: 19px;

	span:not(:last-child)
	{
		margin-right: 13px;
	}
}

@media (max-width: 1199px)
{
	.product-preview
	{
		padding: 15px;
	}

	.product__action
	{
		/*right: 6px;
		top: 6px;*/
	}

	.product-preview__img
	{
		/*height: 188px;*/
	}

	.product-preview__additional
	{
		padding: 0 15px 15px;
		bottom: -5px;
	}
}

@media (max-width: 767px)
{
	.product-preview
	{
		padding: 9px;

		.g-btn, .g-btn-transp
		{
			height: 35px;
			font-size: 12px;
		}

		.g-btn-transp
		{
			padding: 0;
		}
	}

	.product-preview__img
	{
		/*height: 134px;*/
	}

	.product__action
	{
		/*font-size: 10px;
		right: 5px;
		top: 5px;
		height: 22px;*/
	}

	.product-preview__title
	{
		font-size: 13px;
		line-height: 1.3;
		margin-bottom: 13px;
	}

	.product-preview__price
	{
		margin-bottom: 13px;
	}

	.product-preview__price-old
	{
		font-size: 13px;
		line-height: 15px;
		margin-bottom: 5px;
	}

	.product-preview__price-new
	{
		font-size: 16px;
		line-height: 19px;
	}

	.product-preview__additional
	{
		padding: 0 9px 9px;
		bottom: -9px;
	}
}

@media (max-width: 479px)
{
	.product-preview__img
	{
		/*height: 121px;*/
	}
}