/*  Основные цвета  */
$red: #DD2D1F;
$red-hover: #FF3625;
$red-click: #D0271A;
$red-light: #d86e65;
$black: #232323;
$grey: #bdbdbd;
$blue: #57ACE5;
$blue-hover: #6BC1FC;
$blue-click: #428FC2;
$spindle: #BFD7EA;
$colorselectorback: #ecf3f9;
$green: #1ec15f;
$yellow: #f8ba00;
$lightgrey: #efefef;

/* Spacing */
$spacer: 16px;

.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: ($spacer * .25) !important;
}

.mt-2 {
	margin-top: ($spacer * .5) !important;
}