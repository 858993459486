.header-catalog
{
	height: 70px;
	padding: 9px 0;
	border-top: 1px solid rgba($blue, .3);
	border-bottom: 1px solid rgba($blue, .3);
	transition: box-shadow .3s;

	.container
	{
		display: flex;
		height: 100%;
	}

	&--fixed
	{
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
		background-color: #fff;
		border: 0;
		box-shadow: 0px 5px 25px rgba(35, 35, 35, 0.15);
	}
}

.header-catalog__dropdown-wrap
{
	position: relative;
	margin-right: 10px;

/*	.g-btn:focus + .header-catalog-dropdown
	{
		display: flex;
	}*/
	&:focus-within .header-catalog-dropdown
	{
		display: flex;
	}
}

.header-catalog-dropdown
{
	position: absolute;
	top: 60px;
	background: #FFF;
	box-shadow: 0px 4px 30px rgba(35, 35, 35, 0.15);
	border-radius: 2px;
	display: none;
	z-index: 10;
}

.header-catalog-dropdown__list-item
{
	min-width: 387px;
	border-left: 6px solid transparent;
	cursor: pointer;
	position: relative;

	&.is-active
	{
		border-color: $red;
		background-color: #DDE9F3;
		
		.header-catalog-dropdown__list-item-icon svg
		{
			g
			{
				opacity: 1;
			}

			path
			{
				fill: $red;
			}
		}

		.header-catalog-dropdown__sublist
		{
			display: block;
		}
	}

}

.header-catalog-dropdown__list-item-wrap
{
	display: flex;
	align-items: center;
	color: $black;
	padding: 12px 20px;
	transition: .3s;
}

.header-catalog-dropdown__list-item-img
{
	width: 55px;
	height: 55px;
	border-radius: 3px;
	overflow: hidden;
	margin-right: 22px;
	flex-shrink: 0;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.header-catalog-dropdown__list-item-icon
{
	margin-left: auto;
	transition: .3s;

	svg path, svg g
	{
		transition: .3s;
	}
}

.header-catalog-dropdown__sublist
{
	display: none;
	background-color: #DDE9F3;
	min-width: 340px;
	/*padding: 4px 0;*/

	&.is-active
	{
		display: block;
	}
}
.header-catalog-dropdown._catalogDropdown
{
	.header-catalog-dropdown__sublist
	{
		position: absolute;
		top: 0;
		left: 381px;
		
	
		&.is-active
		{
			display: block;
		}
	
	}
}


.header-catalog-dropdown__sublist-item
{
	display: flex;
	align-items: center;
	padding-left: 13px;
}

.header-catalog-dropdown__sublist-item-wrap
{
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 2px 0px 0px 2px;
	flex-grow: 1;
	color: $black;
	transition: .3s;;
	
	&:hover
	{
		background-color: #fff;
	}
}

.header-catalog-dropdown__sublist-item-img
{
	width: 55px;
	height: 55px;
	border-radius: 3px;
	overflow: hidden;
	margin-right: 22px;
	flex-shrink: 0;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.header-catalog__links
{
	display: flex;
	align-items: center;
	margin-left: auto;
}

.header-catalog__links-item
{
	color: $black;
	margin-right: 45px;
	font-size: 16px;
	line-height: 19px;
	transition: .135s;

	&:hover
	{
		color: $red;
	}

	&:last-child
	{
		color: $red;
		@include roboto(500);
		display: flex;
		align-items: center;
		margin-right: 0;

		&:hover
		{
			color: $red-hover;

			.header-catalog__links-item-icon svg path
			{
				fill: $red-hover;
			}
		}
	}
}

.header-catalog__links-item-icon
{
	margin-right: 10px;
	width: 21px;
	height: 21px;

	svg path
	{
		transition: .135s;
	}
}

.header-catalog-search
{
	position: relative;
}

.header-catalog-search__dropdown
{
	display: none;
	min-width: 526px;
	position: absolute;
	top: 60px;
	background-color: #FFF;
	box-shadow: 0px 4px 30px rgba(35, 35, 35, 0.15);
	border-radius: 2px;
	padding: 30px 30px 25px;
	z-index: 10;

	&.is-active
	{
		display: block;
	}
}

.header-catalog-search__list
{
	position: static !important;
	/* --> включить если в впадающую панель добавятся категории
	padding-bottom: 22px;
	border-bottom: 1px solid rgba($black, .1);
	margin-bottom: 18px;
	*/
}

.header-catalog-search__list-item
{
	margin-bottom: 20px;
}

.header-catalog-search__list-item-wrap
{
	display: flex;
	align-items: center;
	@include roboto(500);

	
	&:hover .header-catalog-search__list-item-name
	{
		color: $red;
	}
}

.header-catalog-search__list-item-img
{
	width: 57px;
	height: 57px;
	margin-right: 25px;
	border-radius: 3px;
	overflow: hidden;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.header-catalog-search__list-item-name
{
	max-width: 282px;
	color: $black;
	line-height: 1.45;
	transition: .135s;
}

.header-catalog-search__list-item-price
{
	white-space: nowrap;
	margin-left: auto;
	color: $red;
	font-size: 18px;
}

.header-catalog-search__sublist-title
{
	@include roboto(500);
	color: rgba($black, .3);
	line-height: 1.45;
	margin-bottom: 12px;
}

.header-catalog-search__sublist
{
	columns: 2;
}

.header-catalog-search__sublist-item
{
	margin-bottom: 11px;

	a
	{
		color: $black;
		transition: .135s;

		&:hover
		{
			color: $red;
		}
	}
}

@media (min-width: 1200px)
{
	.header-catalog-dropdown__list-item
	{
		&:hover
		{
			border-color: $red;

			.header-catalog-dropdown__list-item-wrap
			{
				background-color: #DDE9F3;
			}
			
			.header-catalog-dropdown__list-item-icon svg
			{
				g
				{
					opacity: 1;
				}

				path
				{
					fill: $red;
				}
			}
		}
	}

	.header-catalog-dropdown__sublist
	{
		&:hover
		{
			display: block;
		}
	}
}

@media (max-width: 1199px)
{
	.header-catalog
	{
		display: none;
		border: 0;
		padding: 20px 0;
		height: auto;

		&.is-active
		{
			display: block;
		}
	}

	.header-catalog-search
	{
		width: 100%;

		.g-search
		{
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.header-catalog__dropdown-wrap, .header-catalog__links
	{
		display: none;
	}

	.header-catalog-search__dropdown
	{
		width: 100%;
		position: static;
	}
}

@media (max-width: 767px)
{
	.header-catalog-search .g-search
	{
		height: 40px;
	}
	.header-catalog-search__dropdown
	{
		display: none;
	}

	.header-catalog-dropdown__sublist, .header-catalog-dropdown__list-item
	{
		min-width: unset;
	}
}