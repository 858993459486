.installment {
	margin-bottom: 60px;
}

.installment__title {
	margin-bottom: 25px;
}

.installment__descr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

.installment__descr-text {
	max-width: 803px;
	margin-right: 20px;

	p {
		line-height: 1.75;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
}

.installment__descr-card {
	flex-basis: 247px;
	max-width: 247px;
	flex-shrink: 0;
	box-shadow: 2px 5px 15px rgba(35, 35, 35, 0.06);
	border-radius: 3px;
	padding: 23px 36px 28px;
}

.installment__descr-card-title {
	@include roboto(normal, italic);
	color: rgba($black, .35);
	line-height: 1.75;
	margin-bottom: 11px;
}

.installment__descr-card-img {
	width: 174px;

	img {
		max-width: 100%;
		height: auto;
	}
}

.installment__terms {
	display: flex;
	flex-wrap: wrap;
	background-color: rgba($spindle, .2);
	border-radius: 4px;
	padding: 47px 52px 0 60px;
	max-width: 873px;
}

.installment__term {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	flex-basis: 50%;
	max-width: 50%;
	margin-bottom: 51px;

	&:not(:last-child) {
		br {
			display: none;
		}
	}
}

.installment__term-icon {
	margin-right: 25px;
	width: 44px;
	text-align: center;
	flex-shrink: 0;

	img {
		width: 100%;
	}
}

.installment__term-text {
	line-height: 1.75;
}

.installment-calc {
	display: flex;
}



@media (max-width: 1023px) {
	.installment {
		margin-bottom: 40px;
	}

	.installment__title {
		margin-bottom: 20px;
		line-height: 1.3;
	}

	.installment__descr {
		margin-bottom: 20px;
	}

	.installment__descr-text {
		max-width: 466px;
	}

	.installment__descr-card {
		flex-basis: 211px;
		max-width: 211px;
		padding: 21px 30px;
	}

	.installment__descr-card-img {
		width: 151px;
	}

	.installment__terms {
		padding: 40px 45px 0;
	}

	.installment__term {
		margin-bottom: 40px;

		&:not(:last-child) {
			br {
				display: inline;
			}
		}
	}
}

@media (max-width: 767px) {
	.installment {
		margin-bottom: 30px;
	}

	.installment__title {
		margin-bottom: 15px;
		line-height: 1.2;
	}

	.installment__descr {
		flex-direction: column;
		align-items: flex-start;
	}

	.installment__descr-text {
		font-size: 13px;
		margin-bottom: 15px;
		margin-right: 0;
	}

	.installment__descr-card {
		flex-basis: unset;
		max-width: 169px;
		padding: 15px 25px 21px;
	}

	.installment__descr-card-img {
		width: 119px;
	}

	.installment__terms {
		flex-direction: column;
		padding: 25px 24px 8px;
	}

	.installment__term {
		max-width: 100%;
		flex-basis: 100%;
		margin-bottom: 22px;

		&:not(:last-child) {
			br {
				display: none;
			}
		}

		&:last-child {
			br {
				display: none;
			}
		}
	}

	.installment__term-icon {
		width: 38px;
		margin-right: 20px;
	}

	.installment__term-text {
		font-size: 13px;
		line-height: 1.55;
	}
}