.tooltip-block {
	display: none;
	
	background: #fff;
	color: #000;
	/*font-weight: bold;*/
	font-size: 15px;
	border-radius: 4px;
	box-shadow: 0 0 15px rgb(0 0 0 / 28%);
	padding: 15px;
	z-index: 11;
	line-height: 18px;
	
	
	&[data-show] {
		display: block;
	}
	
	.arrow, .arrow::before {
		position: absolute;
		width: 8px;
		height: 8px;
		background: inherit;
	}

	.arrow {
		visibility: hidden;
	}

	.arrow::before {
		visibility: visible;
		content: '';
		transform: rotate(45deg);
	}
	
	&[data-popper-placement^='top'] > .arrow {
		bottom: -4px;
	}

	&[data-popper-placement^='bottom'] > .arrow {
		top: -4px;
	}

	&[data-popper-placement^='left'] > .arrow {
		right: -4px;
	}

	&[data-popper-placement^='right'] > .arrow {
		left: -4px;
	}
	
	.tooltip-block-action:not(:first-of-type) {
		margin-top: 15px;
	}
	
	a, a:visited {
		color: $blue;
	}
}

