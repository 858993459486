.pagination
{
	display: flex !important;
	align-items: center;
}

.pagination__item
{
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba($black, .4);
	font-size: 17px;
	line-height: 20px;
	transition: .135s;
	&:hover
	{
		color: $red;
	}

	&:focus
	{
		color: $red-click;
	}

	&.is-active
	{
		background-color: $red;
		color: #fff;
		border-radius: 2px;
		margin-right: 13px;
	}
}

@media (max-width: 767px)
{
	#pdopage .pagination
	{
		display: none !important;
	}
	.pagination__item
	{
		width: 35px;
		height: 35px;
		font-size: 15px;
		line-height: 18px;
	}
}