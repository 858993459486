body {
  font-size: 16px;
  line-height: 1;
  color: $black;
  @include roboto;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    margin: 0;
    padding: 0;
  }
}

address {
  font-style: normal;
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1,
h2,
.g-title,
.g-title-secondary {
  @include roboto(bold);
  line-height: 1.45;
}

h1,
.g-title {
  font-size: 30px;
}

h2,
.g-title-secondary {
  font-size: 26px;
}

h3,
.g-title-tertiary {
  @include roboto(500);
  font-size: 20px;
  line-height: 23px;
}

h4,
.g-title-quaternary {
  @include roboto(500);
  font-size: 19px;
  line-height: 22px;
}

p {
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0;
  }
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  outline: 0;
  text-decoration: none;
}

.g-link,
a.g-link {
  font-size: 16px;
  color: $red;
  transition: 0.135s;

  &:focus,
  &:visited,
  &:active {
    color: $red-click;

    .g-link__icon {
      svg path {
        color: $red-click;
      }
    }
  }

  &:hover {
    color: $red-hover;

    .g-link__icon {
      svg path {
        fill: $red-hover;
      }
    }
  }

  &--blue {
    color: $blue;

    &:focus,
    &:visited,
    &:active {
      color: $blue-click;
    }

    &:hover {
      color: $blue-hover;
    }
  }

  &--underlined {
    text-decoration: underline;
  }
}

.g-link__icon {
  margin-left: 5px;

  svg path {
    transition: fill 0.135s;
  }
}
.red {
  display: inline-block;
  color: $red;
}
.blue {
  display: inline-block;
  color: $blue;
}
.social-icon {
  opacity: 0.3;
  transition: 0.3s;

  path,
  rect {
    transition: 0.3s;
  }

  &:hover {
    opacity: 1;
  }

  &--fb:hover path {
    fill: #3a559f;
  }

  &--ig:hover path {
    fill: #c536a4;
  }

  &--yt:hover path:first-child {
    fill: #dc472e;
  }

  &--vk:hover path:first-child {
    fill: #44678d;
  }

  &--vb:hover path:first-child {
    fill: #7d3daf;
  }

  &--tg:hover path:first-child {
    fill: #3eb4e1;
  }

  &--ok:hover rect {
    fill: #ffc107;
  }
}

@media (max-width: 767px) {
  h1,
  .g-title {
    font-size: 22px;
  }

  h2,
  .g-title-secondary {
    font-size: 20px;
  }

  h3,
  .g-title-tertiary {
    font-size: 16px;
  }

  h4,
  .g-title-quaternary {
    font-size: 15px;
  }

  .g-link,
  a.g-link {
    font-size: 13px;
  }

  .g-link__icon {
    position: relative;
    top: 1px;
  }
}
