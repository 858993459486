.certificates {

	.doc-type {
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 25px;
		line-height: 1.75;
	}

	.doc-brand {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 25px;
		line-height: 1.75;
	}

	.product-docs {
		/*margin: 0;*/
	}

	.product-doc {
		margin-bottom: 10px;

		.product-doc__inner {
			height: auto;
			padding: 10px;
			border: none;
			
			.product-doc__top {
				margin-bottom: 0;
			}
		}

	}


}