.catalog__title {
	margin-bottom: 20px;
}

.catalog__wrap {
	display: flex;

	&.loading {
		opacity: 0.5;
	}
}

.catalog__sidebar {
	flex-basis: 270px;
	max-width: 270px;
	margin-right: 20px;
	margin-bottom: 50px;
	flex-shrink: 0;

	display: flex;
	flex-direction: column;

	.catalog-feedback {
		margin-top: auto;
		padding: 20px;
		background-color: $blue;
		text-align: center;
		font-weight: 300;
		color: white;
		line-height: 145%;
		position: relative;

		.catalog-feedback-header {
			font-size: 20px;
			font-style: normal;
			margin-bottom: 7px;
		}

		.catalog-feedback-subheader {
			font-size: 15px;
		}


		.catalog-feedback-form {
			display: none;
			position: absolute;
			width: 270px;
			bottom: 57%;
			left: 0;
			transition: all 0.3s;

			background-color: #fff;
			font-size: 16px;
			border: 1px solid #57ACE5;
			box-shadow: 3px 6px 15px rgba(50, 65, 74, .3);
			margin: 0;
			padding: 20px;
			z-index: 1;

			textarea {
				width: 100%;
				height: 124px;
				line-height: 19px;
				resize: none;
				border: none;
				border-bottom: 1px solid rgb(35 35 35 / 30%);
				padding: 6px 12px;
				margin-bottom: 10px;
				transition: all 0.2s;
				color: #000;

				&:focus {
					outline: none !important;
					border: 1px solid #57ACE5;
				}
			}

			button {
				width: 100%;
				position: relative;

				span {
					color: #ffffff;
					transition: all 0.2s;
				}

				&.sending {
					pointer-events: none;
					cursor: not-allowed;

					&::after {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						top: 0;
						bottom: 0;
						right: 30px;
						margin: auto;
						border: 4px solid transparent;
						border-top-color: #ffffff;
						border-radius: 50%;
						animation: button-sending-spinner 1s ease infinite;
					}
				}

				@keyframes button-sending-spinner {
					from {
						transform: rotate(0turn);
					}

					to {
						transform: rotate(1turn);
					}
				}
			}



			&.active {
				display: block;
			}

			&.sended {
				pointer-events: none;
				cursor: not-allowed;

				textarea {
					display: none;
				}

			}

			&:after {
				background-color: #fff;
				border-bottom: 1px solid #57ACE5;
				border-right: 1px solid #57ACE5;
				bottom: -7px;
				content: "";
				height: 12px;
				left: calc(50% - 5px);
				position: absolute;
				transform: scale(1.2, 0.7) rotate(45deg);
				width: 12px;
			}

		}


		@media (max-width: 1099px) {
			display: none;
		}

		.star-rating {
			position: relative;
			white-space: nowrap;
			width: 166px;
			margin: 13px auto;

			svg {
				width: 30px;
				fill: #D2D2D2;
			}

			&.active:hover,
			&.active cover:hover {
				cursor: pointer;
			}

			.cover {
				height: 100%;
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;

				svg {
					fill: $yellow;
				}
			}
		}
	}


}

.catalog__products {
	flex-grow: 1;

	&--previews {
		margin: 0 -15px 30px;

		.catalog__products-list {
			display: flex;
			flex-wrap: wrap;
		}
	}

}

.catalog__products-list {
	width: 100%;
	margin-bottom: 30px;

	&.catalog-items-medium {
		display: flex;
		flex-wrap: wrap;

		.product-preview {
			height: 100%;
			padding: 16px;

			.product-preview__title {
				font-weight: 400;
				min-height: 6em;
			}

			.product-preview__price {
				margin-bottom: 0;

				span.price {
					font-size: 24px;
				}

				span.units {
					color: $red-light;
					font-size: 15px;
				}
			}

			.g-btn {
				margin-top: 10px;
				padding: 0 16px;
			}

			/*
			&:after {
				bottom: -150px;
				content: '';
				display: block;
			}
			
			&:hover{
				.product-preview__additional {
					box-shadow: none;
				}
			
			
				&:after {
					position: absolute;
					left: -90px;
					right: -10px;
					top: -10px;
					box-shadow: 3px 6px 15px rgb(50 65 74 / 30%);
					background: #fff;
					z-index: -3;
				}
			}
			*/
		}
	}
}

.catalog__product {
	flex-basis: calc(100% / 3);
	max-width: calc(100% / 3);
	padding: 0 15px;
	margin-bottom: 15px;
}

.catalog__filter-controls {
	display: none;
}

.catalog-top-panel-group {
	.catalog-top-panel {
		margin-bottom: 1px;

		&:last-child {
			margin-bottom: 20px;
		}
	}


}

.catalog-top-panel {
	border: 1px solid rgba($black, .15);
	border-radius: 3px;
	background-color: #fff;
	display: none;
	padding: 0 15px;
	position: relative;
	z-index: 9;

	&.is-active {
		display: block;
	}

	.catalog-top-panel-header {

		display: flex;
		padding: 4px 0 4px;
		/*flex-wrap: wrap;*/

		/*&:hover .catalog-top-panel-content {*/
		&.is-active .catalog-top-panel-content {
			max-height: unset;
			transition: max-height 1s ease-in-out;
		}


		.catalog-top-panel-text {
			flex-shrink: 0;
			/*font-weight: 700;*/
			margin-right: 10px;
			margin-top: 12px;
		}

		.catalog-top-panel-content {
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			max-height: 38px;

			@media (max-width: 1099px) {
				max-height: unset;
			}

			.catalog-top-panel-item {
				background-color: #fff;
				border: 1px solid #dfdee2;
				border-radius: 3px;
				display: inline-block;
				height: 32px;
				margin: 3px;
				padding: 6px 8px 8px;
				text-decoration: underline;


				color: rgba(35, 35, 35, .5);
				font-size: 15px;
				line-height: 1.25;
				text-overflow: ellipsis;
				transition: max-height 1.4s cubic-bezier(0, 1, 0, 1);
				-webkit-transition: max-height 1.4s cubic-bezier(0, 1, 0, 1);

				&:hover {
					color: $black;
				}

			}

		}

		.catalog-top-panel-button {
			margin-top: 12px;

			.catalog-top-panel-icon {
				cursor: pointer;
				transition: .3s;
			}
		}

		&.is-active .catalog-top-panel-button .catalog-top-panel-icon {
			transform: scaleY(-1);
		}

	}

	&.products-toolbar {
		.catalog-top-panel-header {
			align-items: center;
		}

		.toolbar-item {

			padding: 7px 0;

			display: flex;
			align-items: center;
			gap: 5px;

			.catalog-view-list {
				display: flex;
				gap: 10px;

				.catalog-view-item {

					&:hover,
					&.active {
						color: $red;

						svg {
							fill: $red;
							stroke: $red;
						}
					}
				}
			}


		}

		.toolbar-view {
			padding: 0;
		}

		.toolbar-limit {
			margin-right: 10px;
		}

		.toolbar-sort {
			margin-right: auto;
		}


	}

}







@media (max-width: 1099px) {
	.catalog__wrap {
		flex-wrap: wrap;
	}

	.catalog__filter-controls {
		display: flex;
		margin-bottom: 15px;

		.g-btn-transp:first-child {
			margin-right: 20px;
		}

		.g-btn-transp:last-child {
			min-width: 280px;
		}
	}

	.catalog__sidebar {
		flex-basis: 518px;
		max-width: 518px;
	}

	.catalog-top-panel {
		.catalog-top-panel-header {
			.catalog-top-panel-text {
				display: none;
			}
		}
	}
}

@media (max-width: 767px) {
	.catalog__filter-controls {
		flex-wrap: wrap;
		margin-bottom: 0;

		.g-btn-transp {
			height: 42px;
			width: 100%;
			margin-bottom: 8px;
			font-size: 14px;
		}

		.g-btn-transp--arrow::after {
			top: 12px;
		}

		.g-btn-transp:first-child {
			margin-right: 0;
		}

		.g-btn-transp:last-child {
			min-width: unset;
		}
	}

	.catalog__sidebar {
		margin-right: 0;
		max-width: 100%;
	}

	.catalog__products {
		margin: 0 0 15px;
	}

	.catalog__product {
		flex-basis: 50%;
		max-width: 50%;
		padding: 0 6px;
		margin-bottom: 10px;
	}
}

@media(max-width: 610px) {
	.products-toolbar {
		.toolbar-item {
			label {
				display: none;
			}
		}
	}
}

@media(max-width: 540px) {
	.catalog-top-panel.products-toolbar {
		padding: 0;
		border: none;
		margin-bottom: 10px !important;

		.catalog-top-panel-header {
			padding: 0;

			.toolbar-item {
				padding: 0;
			}
		}

	}
}

@media(max-width: 420px) {
	.catalog-top-panel.products-toolbar {
		.catalog-top-panel-header {
			.toolbar-item {}

			.toolbar-view {
				display: none;
			}

			.toolbar-limit {
				margin-right: 0;
			}
		}
	}
}