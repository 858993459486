.services-main
{
	background-color: rgba($spindle, .2);
	padding: 80px 0 77px;

	.g-show-more
	{
		display: none;
	}
	
	.services__title
	{
		margin-bottom: 30px;
	}

	.services__previews
	{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
	}

	.service-preview
	{
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
		height: 217px;
		padding: 0 12px;
		margin-bottom: 23px;

		.g-link
		{
			margin-top: auto;
		}
	}

	.service-preview__inner
	{
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 27px 30px;
		background-position: right bottom;
		background-repeat: no-repeat;
		background-color: #fff;
	}

	.service-preview__title
	{
		@include roboto(500);
		color: $black;
		font-size: 17px;
		line-height: 1.45;
		margin-bottom: 15px;
		transition: .135s;

		&:hover
		{
			color: $red;
		}
	}

	.service-preview__descr
	{
		font-size: 15px;
		line-height: 1.4;
		color: rgba($black, .45);
		margin-bottom: 10px;
		max-width: 212px;
	}	
}



@media (max-width: 1023px)
{
	.services-main
	{
		padding: 50px 0 30px;
		
		.services__title
		{
			margin-bottom: 25px;
		}

		.service-preview
		{
			flex-basis: 50%;
			max-width: 50%;
			margin-bottom: 20px;
		}

		.service-preview__descr
		{
			max-width: 202px;
		}
	}

}

@media (max-width: 767px)
{
	.services-main
	{
		padding: 40px 0;

		.g-show-more
		{
			display: flex;
		}

		.services__title
		{
			margin-bottom: 15px;
		}
		
		.services__previews
		{
			margin: 0;
			margin-bottom: 18px;

			&.is-active .service-preview:nth-last-child(-n+3)
			{
				display: block;
			}
		}

		.service-preview
		{
			flex-basis: 100%;
			max-width: 100%;
			height: auto;
			min-height: 164px;
			margin-bottom: 8px;
			padding: 0;

			&:not(:first-child) .service-preview__inner
			{
				background-size: auto 90%;
			}

			&:nth-last-child(-n+3)
			{
				display: none;
			}
		}

		.service-preview__inner
		{
			padding: 20px;
			background-size: auto 100%;
		}

		.service-preview__title
		{
			font-size: 14px;
			margin-bottom: 10px;
		}

		.service-preview__descr
		{
			font-size: 13px;
			line-height: 1.3;
			max-width: 141px;

			br
			{
				// display: none;
			}
		}
	}
}