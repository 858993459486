.product__modifications
{
	/*margin: -21px -27px 0 -21px;*/
	// max-height: 310px;
	transition: box-shadow .3s;
	overflow: hidden;

	.product__modifications__list
	{
		display: flex;
		flex-wrap: wrap;
		max-height: 207px;
		overflow-y: auto;

		scrollbar-width: thin;
		scrollbar-color: $red #DEDEDE;
		&::-webkit-scrollbar
		{
			width: 3px;
		}

		&::-webkit-scrollbar-track
		{
			background: #dedede;
		}

		&::-webkit-scrollbar-thumb
		{
			background: $red;
		}
		
		.product__modification
		{
			margin-right: 6px;
			margin-bottom: 6px;
			position: relative;
			cursor: pointer;

			&.is-active::before, &:hover::before
			{
				position: absolute;
				content: '';
				/*width: calc(100% + 12px);
				height: calc(100% + 12px);
				top: -6px;
				left: -6px;*/
				width: 100%;
				height: calc(100% - 3px);
				background-color: transparent;
				border: 2px solid #57ACE5;
				border-radius: 3px;
			}

			img
			{
				object-fit: cover;
				border-radius: 3px;
				/*margin: 6px; */
				width: 60px;
				height: 60px;
			}
		}
		
	}

}

.product-thumb
{
	width: 60px;
	height: 60px;
}

@media (max-width: 1199px)
{
	.product__modifications .product__modifications__list
	{
		max-height: 244px;
	}
}

@media (max-width: 767px)
{
	.product-thumb
	{
		width: 45px;
		height: 45px;

		&.is-active::before
		{
			width: calc(100% + 10px);
			height: calc(100% + 10px);
			top: -5px;
			left: -5px;
		}
	}

	.product-thumbs
	{
		margin: 0;
		overflow: visible;
		max-height: unset;
		&:hover
		{
			box-shadow: none;
		}
	}

	.product-thumbs__list
	{
		max-height: 1000px;
		padding: 0;
		padding-top: 10px;
	}
	
}

